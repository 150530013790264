import React from 'react'
import { LeadSifter } from '../repo/Order'
import { LeadView } from './LeadView'
import { formatDateToMMDDYYYY } from './OrderView'

export const ListView: React.FC<any> = ({ data }) => {
    return (
        <div className='layoutContainer'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <h2 style={headerTextStyle}>{formatDateToMMDDYYYY(data.date)}</h2>
            </div>
            <br/>  
            <LeadView displayedLeads={data.leads} leadSifter={new LeadSifter(data.preferences)}></LeadView>
        </div>
    )
}

const headerTextStyle: React.CSSProperties = {
    color: "black"
}