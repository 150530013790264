import React from 'react'
import City from '../repo/Locations'


interface LocationLookProps {
    city: City,
    searchTerm: String
}

const LocationLook: React.FC<LocationLookProps> = ({ city, searchTerm }) => {
    let search = searchTerm
    if (!search)
        search = "Businesses"
    //const previewURL = `https://www.google.com/maps/search/${search.replace(" ", "+")}+in+${city.cityName.replace(" ", "+")}+${city.stateName}`
    const key = `${city.cityName}${city.stateName}`
    return (
        <div key={key} style={locationContainer}>
            <p style={headerText}>{searchTerm} in {city.cityName}, {city.stateName} USA</p>
            {/* <p className='mb-0'>No previous searches from this area</p>  href={previewURL} target='_blank' rel="noreferrer"*/}
        </div>
    )
}


const headerText: React.CSSProperties = {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '1.1em'
}
  
const locationContainer: React.CSSProperties = {
    border: "1px solid green",
    borderRadius: '30px',
    marginTop: '8px',
    marginBottom: '8px',
    padding: '16px',
    background: '#e0ffe0'
}


export default LocationLook