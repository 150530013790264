import React, { useEffect } from 'react'
import { getAuth } from 'firebase/auth'
import Order, { Filter, LeadSifter, Sort, TextField, formatOrdersJson } from '../repo/Order'
import OrderView from '../components/OrderView'
import { FilterView } from '../components/FilterView'
import { SortView } from '../components/SortView'
import { Column } from '../components/Column'
import { useAuth } from '../contexts/AuthContext'
import Header from '../Header'
import { Link } from 'react-router-dom'

export const MyLeads: React.FC = () => {
    const { user } = useAuth()
    const [orders, setOrders] = React.useState<Order[]>([])
    const [leadSifter, setLeadSifter] = React.useState<LeadSifter>(new LeadSifter(user ? user.preferences : null))
    const [visibleItems, setVisibleItems] = React.useState<TextField[]>([])
    const [invisibleItems, setInvisibleItems] = React.useState<TextField[]>([])

    if (!user) {
      window.location.href = '/log-in'
    }

    useEffect(() => {
        getOrders(setOrders)
    }, [user])

    useEffect(() => {  
      const vis = leadSifter.visibleFields.map((element) => { return new TextField(true, element, () => removeVisible(element, leadSifter, setLeadSifter) ) })
      const invis = leadSifter.hiddenFields.map((element) => { return new TextField(false, element, () => addVisible(element, leadSifter, setLeadSifter) ) })
      setVisibleItems(vis)
      setInvisibleItems(invis)
      savePreferences(leadSifter)
    }, [leadSifter])

    return (
        <>
          <Header/>
            <div style={containerStyle}>
            <div style={menuStyle}>

              <div className="layoutContainer">
                <h2 className='blackText'>Filter</h2>
                  {leadSifter.filters.map((element) =>( 
                    <FilterView filter={element} toggle={() => updateFilter(element, leadSifter, setLeadSifter)} key={`${element.name}`}/>
                  ))}
              </div>
              
              <div className="layoutContainer">
                <h2 className='blackText'>Sort</h2>
                  {leadSifter.sorts.map((element) =>( 
                    <SortView sort={element} apply={() => applySort(element, leadSifter, setLeadSifter)} key={`${element.name}`}/>
                  ))}
              </div>

              <div className="layoutContainer" style={{maxHeight: '400px', overflowY: "auto"}}>
                <h2 className='blackText'>Visible Fields</h2>
                <Column columnItems={visibleItems} setColumnItems={(update) => onReposition(update, setVisibleItems, leadSifter, setLeadSifter)} remove={(f) => removeVisible(f, leadSifter, setLeadSifter)}/>
              </div>
              
              <div className="layoutContainer" style={{maxHeight: '400px', overflowY: "auto"}}>
                <h2 className='blackText'>Hidden Fields</h2>
                <Column columnItems={invisibleItems} setColumnItems={(update) => setInvisibleItems(update)} remove={(f) => addVisible(f, leadSifter, setLeadSifter)}/>
              </div>
              
              <div className='layoutContainer'>
                <p>Need help? Email <a href="mailto:justin@fantasma.dev">justin@fantasma.dev</a></p>
              </div>

            </div>
            <div style={contentStyle}>
              {orders.map((o) => (
                <OrderView isFirst={orders.indexOf(o) === 0} _order={o} leadSifter={leadSifter} key={`${o.id}`}/>
              ))}
              { 
              (orders === null || orders.length === 0) && <h1>No leads yet, collect leads from <Link to="../leads">Let's Collect Leads</Link>!</h1> }
            </div>
          </div>
        </>
      );
    };

    // CSS styles
    const containerStyle: React.CSSProperties = {
      display: 'flex',
      height: 'calc(100vh - 82px)',
    };
    
    const menuStyle: React.CSSProperties = {
      flex: '0 0 20%', // The menu takes 20% of the available width
      minWidth: '200px', // Set a minimum width of 200px
      background: '#013e06', // Menu background color
      overflowY: 'auto', // Enable vertical scrolling if content exceeds the viewport height
      padding: '12px',
      border: '1px black'
    };
    
    const contentStyle: React.CSSProperties = {
      flex: '1', // The content takes up the remaining available width
      overflowY: 'auto', // Enable vertical scrolling if content exceeds the viewport height
      background: '#d4e3d5',
      padding: '12px'
    };

const getOrders = (setOrders: React.Dispatch<React.SetStateAction<Order[]>>) => {
    getAuth().currentUser?.getIdToken().then((token) => {
        fetch(process.env.REACT_APP_SERVER_URL + `/leadslist`, {
          method: "GET",
          headers: {
            Authorization: `${token}`
          },
        })
        .then((response) => response.json())
        .then((json) => {
          console.log(json)
            const orders = formatOrdersJson(json)
            console.log(orders)
            setOrders(orders)
        })
        .catch((error) => {
            console.error('Error:' , error);
        });
      })
}


const savePreferences = (leadSifter: LeadSifter) => {
  getAuth().currentUser?.getIdToken().then((token) => {
    fetch(process.env.REACT_APP_SERVER_URL + `/preferences`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        leadSifter
      }),
    })
    .catch((error) => {
      console.error('Error:', error);
      // Handle error if the request fails
    });
  })
}

const updateFilter = (filter: Filter, leadSifter: LeadSifter, setLeadSifter: React.Dispatch<React.SetStateAction<LeadSifter>>) => {
  filter.active = !filter.active
  setLeadSifter(new LeadSifter(leadSifter))
}

const applySort = (sort: Sort, leadSifter: LeadSifter, setLeadSifter: React.Dispatch<React.SetStateAction<LeadSifter>>) => {
  leadSifter.sorts.forEach(s => {
    s.active = false
  })
  sort.active = true
  setLeadSifter(new LeadSifter(leadSifter))
}

const removeVisible = (field: string, leadSifter: LeadSifter, setLeadSifter: React.Dispatch<React.SetStateAction<LeadSifter>>) => {
  leadSifter.visibleFields = leadSifter.visibleFields.filter((s) => field !== s)
  leadSifter.hiddenFields.unshift(field)
  setLeadSifter(new LeadSifter(leadSifter))
}

const addVisible = (field: string, leadSifter: LeadSifter, setLeadSifter: React.Dispatch<React.SetStateAction<LeadSifter>>) => {
  leadSifter.hiddenFields = leadSifter.hiddenFields.filter((s) => field !== s)
  leadSifter.visibleFields.push(field)
  setLeadSifter(new LeadSifter(leadSifter))
}

const onReposition = (update: TextField[], set: React.Dispatch<React.SetStateAction<TextField[]>>, leadSifter: LeadSifter, setLeadSifter: React.Dispatch<React.SetStateAction<LeadSifter>>) => {
  set(update)
  leadSifter.visibleFields = update.map((s) => s.text)
  setLeadSifter(new LeadSifter(leadSifter))
}
