import React, { useState } from 'react';
import logo from './logo.png'; // Import the logo image

const footerStyles: React.CSSProperties = {
    background: 'black',
    color: '#7fc94c',
    padding: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  };
  
  const logoImageStyles: React.CSSProperties = {
    maxWidth: '100px',
    height: 'auto'
  };
  
  const sectionStyles: React.CSSProperties = {
    flexBasis: '200px',
    marginBottom: '20px',
  };
  
  const linkStyles: React.CSSProperties = {
    display: 'block',
    color: 'inherit',
    textDecoration: 'none',
    marginBottom: '5px',
  };
  
  const contactButtonStyles: React.CSSProperties = {
    padding: '8px 16px',
    background: 'transparent',
    border: '1px solid #7fc94c',
    borderColor: '#7fc94c',
    borderRadius: '4px',
    color: '#7fc94c',
    cursor: 'pointer',
    transition: 'border-color 0.3s, background-color 0.3s',
    fontSize: '1em',
    margin: '0',
    display: 'inline-block',
    position: 'relative',
    zIndex: 1,
    overflow: 'hidden',
    boxShadow: 'none',
    outline: 'none',
  };

  const contactButtonHoverStyle: React.CSSProperties = {
    borderColor: 'white',
    background: 'rgba(255, 255, 255, 0.1)',
    textDecoration: 'underline',
  };
  
  const copyrightStyles: React.CSSProperties = {
    width: '100%',
    textAlign: 'center',
    marginTop: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };


function Footer() {
    const [isButtonHovered, setIsButtonHovered] = useState(false);

    const handleMouseEnter = () => {
      setIsButtonHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsButtonHovered(false);
    };
  return (
    <footer style={footerStyles}>

      <div style={sectionStyles}>
        <h3>Product</h3>
        <a href="/pricing" style={linkStyles}>Pricing</a>
        <a href="/resources" style={linkStyles}>Resources</a>
        <a href="/faqs" style={linkStyles}>FAQs</a>
      </div>

      <div style={sectionStyles}>
        <h3>Legal</h3>
        <a href="/privacy-policy" style={linkStyles}>Privacy Policy</a>
        <a href="/terms-of-service" style={linkStyles}>Terms of Service</a>
      </div>

        <div style={sectionStyles}>
        <h3>Connect</h3>
        <a href="https://www.facebook.com/" style={linkStyles}>Facebook</a>
        <a href="https://www.twitter.com/" style={linkStyles}>Twitter</a>
        <button style={{
          ...contactButtonStyles,
          ...(isButtonHovered && contactButtonHoverStyle),
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
         onClick={() => window.open('mailto:justin@fantasma.dev')}>
            Have questions? justin@fantasma.dev
        </button>
        </div>
      <div style={copyrightStyles}>
        <img src={logo} alt="Leadlat Logo" style={logoImageStyles} />
        <p>&copy; {new Date().getFullYear()}</p>
      </div>
    </footer>
  );
}
export default Footer;
