import React, { useEffect } from 'react'
import Header from '../Header'
import { ListView } from '../components/ListView'

export const MyLeadsList: React.FC = () => {
    const [data, setData] = React.useState<any>(null)

    useEffect(() => {
      getList(setData)
    }, [])

    return (
        <>
          <Header/>
          <div style={contentStyle}>
            { data != null && <ListView data={data}/> }
          </div>
        </>
      );
    };

    const contentStyle: React.CSSProperties = {
      flex: '1', // The content takes up the remaining available width
      overflowY: 'auto', // Enable vertical scrolling if content exceeds the viewport height
      background: '#d4e3d5',
      minHeight: '100vh',
      padding: '12px'
    };

const getList = (setData: React.Dispatch<React.SetStateAction<any>>) => {
  const id = window.location.pathname.split('/')[2]
  fetch(process.env.REACT_APP_SERVER_URL + `/list/${id}`, {
    method: "GET",
  })
  .then((response) => response.json())
  .then((json) => {
    setData(json.data)
  })
  .catch((error) => {
      console.error('Error:' , error);
  });
}
