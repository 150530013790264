import React from 'react'
import City from '../repo/Locations'
import Order, { Lead, LeadSifter, formatLeadsFile, formatOrderJson } from '../repo/Order'
import { getAuth } from 'firebase/auth'
import { LeadView } from './LeadView'
import { CheckboxView } from './CheckboxView'
import { useAuth } from '../contexts/AuthContext'

interface OrderProps {
    isFirst: boolean
    _order: Order,
    leadSifter: LeadSifter
}

export class Checkbox {
    isChecked: boolean
    city: City
    count: number
    visibleCount: number
    constructor(isChecked: boolean, city: City) {
        this.isChecked = isChecked
        this.city = city
        this.count = 0
        this.visibleCount = 0
    }
}

const OrderView: React.FC<OrderProps> = ({ isFirst, _order, leadSifter }) => {
    const [order, setOrder] = React.useState<Order>(_order)
    const [locations, setLocations] = React.useState<Checkbox[]>(_order.locations.map((l) => new Checkbox(true, l)))
    const [leads, setLeads] = React.useState<any>([])
    const [displayedLeads, setDisplayedLeads] = React.useState<Lead[] | null>(formatLeadsFile(leads, leadSifter, locations))
    const [allChecked, setAllChecked] = React.useState<boolean>(true)
    const [isLoaded, setIsLoaded] = React.useState<boolean>(isFirst)
    const { user } = useAuth()

    React.useEffect(() => {
      const update = formatLeadsFile(leads, leadSifter, locations)
      if (update)
        setDisplayedLeads(update)
    }, [leadSifter, locations, leads, order])

    React.useEffect(() => {
      setLocations(order.locations.map((l) => new Checkbox(true, l)))
    }, [order])

    React.useEffect(() => {
        if (isFirst && leads.length === 0){
            getLeadsForList(`${_order.id}`, _order.locations.length, setLeads, order, setOrder)
        }
    }, [isFirst, _order, order, leads])

    React.useEffect(() => {
        setAllChecked(locations.filter((f) => !f.isChecked).length === 0)
    }, [locations])

    let visCount = 0
    let totalCount = 0
    for (let i in locations) {
        totalCount += locations[i].count
        visCount += locations[i].visibleCount
    }

    return (
        <div className='layoutContainer'>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <h2 style={headerTextStyle}>{order.query} {formatDateToMMDDYYYY(order.date)}</h2>
                <div style={{ maxWidth: '200px', alignSelf: 'flex-start' }}>
                    <button className='roundedButtonStyle' onClick={() => downloadLeads(`${order.query}`, displayedLeads, leadSifter)}>Download Leads</button>
                    <button className='roundedButtonStyle' onClick={() => createLeadlist(user.preferences, displayedLeads, order.id)}>Create Contact List w/ Selected</button>
                    <button className='roundedButtonStyle' onClick={() => window.location.href = `/list/${order.id}`}>View Order's Contact List</button>
                </div>
            </div>
                {!order.isComplete ? 
                    <p className='plainBlackText'>⏳ Data will come in shortly. You can come back later.</p>    
                    : 
                    order.error ? 
                    <p className='plainBlackText'>{order.error}</p>    
                    : <div/>
                }
            <div>
            <br/>  
            <CheckboxView isChecked={allChecked} 
                        text={`Select All ${isLoaded ? `(${visCount}/${totalCount})` : ""}`} 
                        toggle={() => {
                            setLocations(
                                locations.filter((l) => {
                                    l.isChecked = !allChecked
                                    return l
                                })
                            )
                        }}/>
                <div style={{maxHeight: "150px", overflowY: 'auto'}}>
                    <ol style={{margin: '0px', display: 'inline-block'}}>
                        {locations.map((l, index) => (
                            <CheckboxView isChecked={l.isChecked}
                            key={`${l.city.cityName}${l.city.stateName}`}
                            text={`${l.city.cityName}, ${l.city.stateName} ${isLoaded ? `(${l.visibleCount}/${l.count})` : ""}`} 
                            toggle={() => {toggleLocations(l, locations, setLocations)}}/>
                        ))}
                    </ol>
                </div>
            </div>
                <br/>
            
            {isLoaded ? <LeadView displayedLeads={displayedLeads} leadSifter={leadSifter}></LeadView> : 
                <button className='roundedButtonStyle' onClick={() => {
                    setIsLoaded(true)
                    getLeadsForList(`${_order.id}`, order.locations.length, setLeads, order, setOrder)}}>Load Leads</button>
            }
        </div>
    )
}    

function createLeadlist(preferences: any, leads: Lead[] | null, orderId: any) {
    if (!leads || leads.length === 0) {
        return alert("No leads to create a shareable calling list from.")
    }
    getAuth().currentUser?.getIdToken().then((token) => {
        fetch(process.env.REACT_APP_SERVER_URL + `/createList`, {
            method: "POST",
            headers: {
              Authorization: `${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId: getAuth().currentUser?.uid,
                preferences: preferences,
                leads: leads,
                orderId: orderId,
            }),
        })
        .then((response) => response.json())
        .then((json) => {
            window.location.href = `/list/${json.id}`
        })
        .catch((error) => {
            console.error(error)
        })
    })

}

const headerTextStyle: React.CSSProperties = {
    color: "black"
}

const toggleLocations = (location: Checkbox, locations: Checkbox[], setLocations: React.Dispatch<React.SetStateAction<Checkbox[]>>) => {
    location.isChecked = !location.isChecked
    setLocations(locations.map((l) => new Checkbox(l.isChecked, l.city)))
}

const getLeadsForList = async (id: string, total: number, setLeads: React.Dispatch<React.SetStateAction<any>>, order: Order, setOrder: React.Dispatch<React.SetStateAction<Order>>) => {
    getAuth().currentUser?.getIdToken().then((token) => {
        fetch(process.env.REACT_APP_SERVER_URL + `/order/${order.id}`, {
            method: "GET",
            headers: {
              Authorization: `${token}`
            },
        })
        .then((r) => r.json())
        .then(async (j) => {
            console.log("Json", j)
            setOrder(formatOrderJson(j.data))



        })
        .catch((error) => {
            console.log(error)
        })

        fetch(process.env.REACT_APP_SERVER_URL + `/leadlist/${id}`, {
            method: "GET",
            headers: {
              Authorization: `${token}`
            },
          })
          .then((response) => response.json())
          .then(async (json) => {
              if (order.isComplete || order.error) {
                  setLeads(json.data)
              } else {
                  setLeads(json.data)
                  await sleep(15000)
                  getLeadsForList(id, total, setLeads, order, setOrder)
              }
          })
          .catch(async (error) => {
              console.error('Error:' , error);
              await sleep(15000)
              getLeadsForList(id, total, setLeads, order, setOrder)
          });

      })
}

function sleep(milliseconds: number) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export function formatDateToMMDDYYYY(dateString: string): string {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    return formattedDate;
  }

  function downloadLeads(fileName: string, leads: Lead[] | null, leadSifter: LeadSifter) {
    if (!leads) return
  
    const topRowValues = leadSifter.visibleFields;
  
    // Convert the data to CSV format
    const csvRows: string[] = [];
    csvRows.push(`${topRowValues.map((s) => `"${s}"`).join(",")}`)
    leads.forEach((l) => {
        csvRows.push(`${l.values.map((s) => `"${s}"`).join(",")}`)
    })
    const csvContent = csvRows.join("\n");
  
    // Create a Blob with the CSV data
    const blob = new Blob([csvContent], { type: "text/csv" });
  
    // Create a download link and trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  
    // Cleanup the URL object after the download
    URL.revokeObjectURL(link.href);
  }

export default OrderView
