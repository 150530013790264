import { useContext, useState, useEffect, createContext } from "react"
import { auth } from "../firebase"
import { signOut, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";

const AuthContext = createContext()

const actionCodeSettings = {
  url: 'https://laplead.com/auth-link',
  handleCodeInApp: true,
};

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  async function checkIfSignInWithEmail(enteredEmail) {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      console.log(email)
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      await signInWithEmailLink(auth, email, window.location.href)
        .then(() => {
          window.localStorage.removeItem('emailForSignIn');
          return true
        })
        .catch((error) => {
          console.log(error)
          return false
        });
    }
    return false
  }
  
  async function loginWithEmail(email) {
    console.log("login with email ", email)
    window.localStorage.setItem("emailForSignIn", email);
    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    } catch (error) {
      console.error('Error sending sign-in link: ', error);
      alert("Error sending sign-in link. Try another")
    }
  }

  function logout() {
    window.location.href = '/'
    return signOut(auth)
  }

  useEffect(() => {
    const fetchData = async () => {
      const unsubscribe = auth.onAuthStateChanged(async user => {
        if (user) {
          const userData = await getUserInDb(user);
          setCurrentUser(userData);
        }
        setLoading(false);
      });
      return unsubscribe;
    };
    fetchData();
  }, []);

  const value = {
    user: currentUser,
    logout,
    loginWithEmail,
    checkIfSignInWithEmail
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading ? children : <h2 style={{ height: '100vh', width:'100vw', background: "#013e06", color: "white"}}>Loading...</h2>}
    </AuthContext.Provider>
  )
}

const getUserInDb = async (firebaseUser) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await auth.currentUser?.getIdToken();
      const response = await fetch(process.env.REACT_APP_SERVER_URL + `/authin`, {
        method: "GET",
        headers: {
          Authorization: `${token}`
        },
      });
      const json = await response.json();
      const user = json.user;
      const userData = {
        user: firebaseUser,
        credits: user.credits,
        escrow_credits: user.escrow_credits,
        locations: user.locations,
        query: user.query,
        preferences: user.preferences,
      };
      resolve(userData);
    } catch (error) {
      reject(error);
    }
  });
};
