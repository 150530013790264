import React, { useState } from 'react';
import { categories } from '../repo/BusinessCategories';

function CustomDropdownEditbox(p) {
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    p.setSearch(inputValue);
    const fetchedSuggestions = [inputValue, ...categories].filter((suggestion) =>
      suggestion.toLowerCase().includes(inputValue.toLowerCase())
    );
    setSuggestions(fetchedSuggestions);
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (suggestion) => {
    p.setSearch(suggestion);
    setShowSuggestions(false);
  };
  

  return (
    <div className="autocomplete">
      <input
        type="text"
        value={p.search}
        onChange={handleInputChange}
        onClick={() => setShowSuggestions(true)}
        onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
        placeholder="Enter Business..."
      />
      {showSuggestions && (
        <ul className="suggestion-list">
          {suggestions.map((suggestion, index) => (
            <li className='suggestion-item' key={index} onClick={() => handleSuggestionClick(suggestion)}>
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default CustomDropdownEditbox;
