import React, { useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import greenpin from '../greenpin.png'; // Import the logo image
import { getAuth } from 'firebase/auth';
import { formatDateToMMDDYYYY } from './OrderView';

const mapContainerStyle= {
  height: '100%',
  width: '100%'
};

const Map = (p) => {
  const [mapCenter, setMapCenter] = useState({ lat: 41.7911, lng: -107.2387 });
  const [zoom, setZoom] = useState(4);
  const [prevLocations, setPrevLocations] = React.useState([])
  const [selectedLocation, setSelectedLocation] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  })
  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds)
  }, [])
  const onUnmount = React.useCallback(function callback(_) {
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setMapCenter({ lat: 39.0911, lng: -101.2387 })
      setZoom(5)
    }, 1000)
  }, []);

  useEffect(() => {
      fetchPrevLocations(setPrevLocations)
  }, [])

  const onMapClick = (event) => {
    event.stop()
    const lat = event.latLng.lat()
    const lng = event.latLng.lng()
    const nearestLocation = p.getNearestCity(lat, lng)
    if (p.locations.filter((l) => l.lat === nearestLocation.lat && l.lng === nearestLocation.lng).length === 0)
      p.setLocations((prev) => [nearestLocation, ...prev]);
  }

  const onMarkerClick = (city) => {
    const updatedLocations = p.locations.filter(  
      (m) => m.lat !== city.lat && m.lng !== city.lng
    );
  
    p.setLocations(updatedLocations);
  };

  const onPreviousQueryClick = (location) => {
    setSelectedLocation(location);
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={mapCenter}
      zoom={zoom}  
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={onMapClick}
    >

      {
        prevLocations.map((it) => {
          console.log(it)
          return <Marker key={`${it._id}`} position={{ lat: it.lat, lng: it.lng }} icon={greenpin} onClick={() => onPreviousQueryClick(it)} />
        })
      }
      {p.locations.map((city, index) => (
          <Marker key={`${index}`} position={{ lat: city.lat, lng: city.lng }} onClick={() => onMarkerClick(city)} />
        ))} 
      {selectedLocation && (
        <InfoWindow
          position={{ lat: selectedLocation.lat, lng: selectedLocation.lng }}
          onCloseClick={() => setSelectedLocation(null)}
        >
          <div>
            <h3>{formatDateToMMDDYYYY(selectedLocation.date)}</h3>
            <p>{selectedLocation.query}</p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : <></>
};


const fetchPrevLocations = (setLocations) => {
  console.log("Hello")
  getAuth().currentUser?.getIdToken().then((token) => {
    fetch(process.env.REACT_APP_SERVER_URL + `/previous-locations`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      }
    })
    .then((response) => response.json())
    .then((json) => {
      console.log("getting prev locations", json.data)
      setLocations(json.data)
    })
    .catch((error) => {
      console.error('Error:', error);
      // Handle error if the request fails
    });
  })
}

export default Map;
