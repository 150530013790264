const locations = [
  '32.9440,-85.9539,Alexander City,Alabama',
  '31.3085,-86.4833,Andalusia,Alabama',
  '33.6598,-85.8316,Anniston,Alabama',
  '34.8029,-86.9717,Athens,Alabama',
  '31.0238,-87.4939,Atmore,Alabama',
  '32.6099,-85.4808,Auburn,Alabama',
  '33.4018,-86.9544,Bessemer,Alabama',
  '33.5186,-86.8104,Birmingham,Alabama',
  '30.7638,-88.0747,Chickasaw,Alabama',
  '32.8387,-86.6294,Clanton,Alabama',
  '34.1748,-86.8436,Cullman,Alabama',
  '34.6059,-86.9833,Decatur,Alabama',
  '32.5176,-87.8364,Demopolis,Alabama',
  '31.2232,-85.3905,Dothan,Alabama',
  '31.3152,-85.8552,Enterprise,Alabama',
  '31.8913,-85.1455,Eufaula,Alabama',
  '34.7998,-87.6773,Florence,Alabama',
  '34.4443,-85.7197,Fort Payne,Alabama',
  '34.0143,-86.0066,Gadsden,Alabama',
  '31.8296,-86.6178,Greenville,Alabama',
  '34.3581,-86.2947,Guntersville,Alabama',
  '34.7304,-86.5861,Huntsville,Alabama',
  '33.8312,-87.2775,Jasper,Alabama',
  '32.6324,-87.3192,Marion,Alabama',
  '30.6954,-88.0399,Mobile,Alabama',
  '32.3792,-86.3077,Montgomery,Alabama',
  '32.6454,-85.3783,Opelika,Alabama',
  '31.4590,-85.6403,Ozark,Alabama',
  '32.4710,-85.0008,Phenix City,Alabama',
  '30.7452,-88.0897,Prichard,Alabama',
  '34.6723,-86.0341,Scottsboro,Alabama',
  '32.4074,-87.0211,Selma,Alabama',
  '34.7651,-87.6986,Sheffield,Alabama',
  '33.1732,-86.2516,Sylacauga,Alabama',
  '33.4359,-86.1058,Talladega,Alabama',
  '31.8088,-85.9700,Troy,Alabama',
  '33.2098,-87.5692,Tuscaloosa,Alabama',
  '34.7312,-87.7025,Tuscumbia,Alabama',
  '32.4302,-85.7077,Tuskegee,Alabama',
  '61.2176,-149.8997,Anchorage,Alaska',
  '60.5424,-145.7525,Cordova,Alaska',
  '64.8401,-147.7200,Fairbanks,Alaska',
  '59.2351,-135.4473,Haines,Alaska',
  '59.6481,-151.5299,Homer,Alaska',
  '58.3005,-134.4201,Juneau,Alaska',
  '55.3422,-131.6461,Ketchikan,Alaska',
  '57.7900,-152.4072,Kodiak,Alaska',
  '66.8969,-162.5931,Kotzebue,Alaska',
  '64.5006,-165.4086,Nome,Alaska',
  '61.5994,-149.1146,Palmer,Alaska',
  '60.1048,-149.4421,Seward,Alaska',
  '57.0532,-135.3346,Sitka,Alaska',
  '59.4572,-135.3145,Skagway,Alaska',
  '61.1309,-146.3499,Valdez,Alaska',
  '32.3717,-112.8607,Ajo,Arizona',
  '33.4356,-112.3496,Avondale,Arizona',
  '31.4482,-109.9284,Bisbee,Arizona',
  '32.9110,-111.7734,Casa Grande,Arizona',
  '33.3062,-111.8413,Chandler,Arizona',
  '33.0509,-109.2962,Clifton,Arizona',
  '31.3445,-109.5453,Douglas,Arizona',
  '35.1983,-111.6513,Flagstaff,Arizona',
  '33.0315,-111.3873,Florence,Arizona',
  '32.9478,-112.7168,Gila Bend,Arizona',
  '33.5387,-112.1860,Glendale,Arizona',
  '33.3942,-110.7865,Globe,Arizona',
  '35.1894,-114.0530,Kingman,Arizona',
  '34.4839,-114.3225,Lake Havasu City,Arizona',
  '33.4152,-111.8315,Mesa,Arizona',
  '31.3404,-110.9343,Nogales,Arizona',
  '35.8764,-110.6404,Oraibi,Arizona',
  '33.4484,-112.0740,Phoenix,Arizona',
  '34.5400,-112.4685,Prescott,Arizona',
  '33.4949,-111.9217,Scottsdale,Arizona',
  '31.5455,-110.2773,Sierra Vista,Arizona',
  '33.4255,-111.9400,Tempe,Arizona',
  '31.7129,-110.0676,Tombstone,Arizona',
  '32.2540,-110.9742,Tucson,Arizona',
  '35.8322,-110.3979,Walpi,Arizona',
  '35.6806,-109.0526,Window Rock,Arizona',
  '35.0242,-110.6974,Winslow,Arizona',
  '32.6927,-114.6277,Yuma,Arizona',
  '34.1210,-93.0535,Arkadelphia,Arkansas',
  '34.0237,-91.3437,Arkansas Post,Arkansas',
  '35.7697,-91.6409,Batesville,Arkansas',
  '34.5693,-92.5854,Benton,Arkansas',
  '35.9285,-89.9080,Blytheville,Arkansas',
  '33.5836,-92.8341,Camden,Arkansas',
  '35.0918,-92.4367,Conway,Arkansas',
  '33.1241,-91.9612,Crossett,Arkansas',
  '33.2075,-92.6656,El Dorado,Arkansas',
  '36.0627,-94.1606,Fayetteville,Arkansas',
  '35.0093,-90.7852,Forrest City,Arkansas',
  '35.3844,-94.4210,Fort Smith,Arkansas',
  '36.2298,-93.1074,Harrison,Arkansas',
  '34.5291,-90.5900,Helena,Arkansas',
  '33.6685,-93.5971,Hope,Arkansas',
  '34.5078,-93.0546,Hot Springs,Arkansas',
  '34.8674,-92.1270,Jacksonville,Arkansas',
  '35.8357,-90.7051,Jonesboro,Arkansas',
  '34.7445,-92.2880,Little Rock,Arkansas',
  '33.2671,-93.2394,Magnolia,Arkansas',
  '35.1526,-92.7438,Morrilton,Arkansas',
  '35.6061,-91.2641,Newport,Arkansas',
  '34.7561,-92.2677,North Little Rock,Arkansas',
  '35.7029,-89.9701,Osceola,Arkansas',
  '34.2249,-92.0034,Pine Bluff,Arkansas',
  '36.3335,-94.1257,Rogers,Arkansas',
  '35.2438,-91.7364,Searcy,Arkansas',
  '34.4941,-91.5581,Stuttgart,Arkansas',
  '35.4589,-94.3566,Van Buren,Arkansas',
  '35.1465,-90.1848,West Memphis,Arkansas',
  '37.7799,-122.2822,Alameda,California',
  '34.0953,-118.1270,Alhambra,California',
  '33.8366,-117.9143,Anaheim,California',
  '38.0049,-121.8058,Antioch,California',
  '34.1397,-118.0353,Arcadia,California',
  '35.3733,-119.0187,Bakersfield,California',
  '34.8958,-117.0173,Barstow,California',
  '37.5202,-122.2758,Belmont,California',
  '37.8715,-122.2730,Berkeley,California',
  '34.0736,-118.4004,Beverly Hills,California',
  '33.9165,-117.9003,Brea,California',
  '33.8674,-117.9981,Buena Park,California',
  '34.1808,-118.3090,Burbank,California',
  '32.6789,-115.4989,Calexico,California',
  '38.5788,-122.5797,Calistoga,California',
  '33.1581,-117.3506,Carlsbad,California',
  '36.5552,-121.9233,Carmel,California',
  '39.7285,-121.8375,Chico,California',
  '32.6401,-117.0842,Chula Vista,California',
  '34.0967,-117.7198,Claremont,California',
  '33.8958,-118.2201,Compton,California',
  '37.9780,-122.0311,Concord,California',
  '33.8753,-117.5664,Corona,California',
  '32.6859,-117.1831,Coronado,California',
  '33.6638,-117.9047,Costa Mesa,California',
  '34.0211,-118.3965,Culver City,California',
  '37.6879,-122.4702,Daly City,California',
  '38.5449,-121.7405,Davis,California',
  '33.9401,-118.1332,Downey,California',
  '32.7920,-115.5631,El Centro,California',
  '37.9161,-122.3108,El Cerrito,California',
  '34.0686,-118.0276,El Monte,California',
  '33.1192,-117.0864,Escondido,California',
  '40.8021,-124.1637,Eureka,California',
  '38.2492,-122.0405,Fairfield,California',
  '34.0922,-117.4350,Fontana,California',
  '37.5485,-121.9886,Fremont,California',
  '36.7378,-119.7871,Fresno,California',
  '33.8704,-117.9242,Fullerton,California',
  '33.7743,-117.9380,Garden Grove,California',
  '34.1425,-118.2551,Glendale,California',
  '37.6688,-122.0810,Hayward,California',
  '34.0928,-118.3287,Hollywood,California',
  '33.6595,-117.9988,Huntington Beach,California',
  '33.7206,-116.2156,Indio,California',
  '33.9617,-118.3531,Inglewood,California',
  '33.6846,-117.8265,Irvine,California',
  '33.9319,-117.9461,La Habra,California',
  '33.5427,-117.7854,Laguna Beach,California',
  '34.6868,-118.1542,Lancaster,California',
  '37.6819,-121.7685,Livermore,California',
  '38.1341,-121.2722,Lodi,California',
  '34.6392,-120.4579,Lompoc,California',
  '33.7701,-118.1937,Long Beach,California',
  '34.0522,-118.2437,Los Angeles,California',
  '34.0259,-118.7798,Malibu,California',
  '38.0194,-122.1341,Martinez,California',
  '39.1457,-121.5914,Marysville,California',
  '37.4530,-122.1817,Menlo Park,California',
  '37.3022,-120.4830,Merced,California',
  '37.6393,-120.9970,Modesto,California',
  '36.6002,-121.8947,Monterey,California',
  '37.3861,-122.0839,Mountain View,California',
  '38.2975,-122.2869,Napa,California',
  '34.8481,-114.6141,Needles,California',
  '33.6189,-117.9298,Newport Beach,California',
  '33.9022,-118.0817,Norwalk,California',
  '38.1074,-122.5697,Novato,California',
  '37.8044,-122.2712,Oakland,California',
  '33.1959,-117.3795,Oceanside,California',
  '34.4480,-119.2429,Ojai,California',
  '34.0633,-117.6509,Ontario,California',
  '33.7879,-117.8553,Orange,California',
  '39.5138,-121.5564,Oroville,California',
  '34.1975,-119.1771,Oxnard,California',
  '36.6177,-121.9166,Pacific Grove,California',
  '33.8303,-116.5453,Palm Springs,California',
  '34.5794,-118.1165,Palmdale,California',
  '37.4419,-122.1430,Palo Alto,California',
  '34.1478,-118.1445,Pasadena,California',
  '38.2324,-122.6367,Petaluma,California',
  '34.0551,-117.7500,Pomona,California',
  '34.1478,-119.1951,Port Hueneme,California',
  '34.1064,-117.5931,Rancho Cucamonga,California',
  '40.1785,-122.2358,Red Bluff,California',
  '40.5865,-122.3917,Redding,California',
  '34.0556,-117.1825,Redlands,California',
  '33.8492,-118.3884,Redondo Beach,California',
  '37.4848,-122.2281,Redwood City,California',
  '37.9358,-122.3477,Richmond,California',
  '33.9806,-117.3755,Riverside,California',
  '38.7521,-121.2880,Roseville,California',
  '38.5816,-121.4944,Sacramento,California',
  '36.6777,-121.6555,Salinas,California',
  '34.1083,-117.2898,San Bernardino,California',
  '33.4274,-117.6126,San Clemente,California',
  '32.7157,-117.1611,San Diego,California',
  '34.2819,-118.4390,San Fernando,California',
  '37.7749,-122.4194,San Francisco,California',
  '34.0961,-118.1058,San Gabriel,California',
  '37.3387,-121.8853,San Jose,California',
  '33.5019,-117.6628,San Juan Capistrano,California',
  '37.7258,-122.1569,San Leandro,California',
  '35.2828,-120.6596,San Luis Obispo,California',
  '37.5630,-122.3255,San Mateo,California',
  '33.7361,-118.2922,San Pedro,California',
  '37.9735,-122.5311,San Rafael,California',
  '35.6440,-121.1893,San Simeon,California',
  '33.7455,-117.8677,Santa Ana,California',
  '34.4208,-119.6982,Santa Barbara,California',
  '37.3541,-121.9552,Santa Clara,California',
  '34.3917,-118.5426,Santa Clarita,California',
  '36.9741,-122.0308,Santa Cruz,California',
  '34.0195,-118.4912,Santa Monica,California',
  '38.4404,-122.7141,Santa Rosa,California',
  '37.8591,-122.4853,Sausalito,California',
  '34.2694,-118.7815,Simi Valley,California',
  '38.2919,-122.4580,Sonoma,California',
  '37.6547,-122.4077,South San Francisco,California',
  '37.9577,-121.2908,Stockton,California',
  '37.3688,-122.0363,Sunnyvale,California',
  '40.4163,-120.6530,Susanville,California',
  '34.1706,-118.8376,Thousand Oaks,California',
  '33.8358,-118.3406,Torrance,California',
  '37.4946,-120.8460,Turlock,California',
  '39.1502,-123.2078,Ukiah,California',
  '38.1041,-122.2566,Vallejo,California',
  '34.2805,-119.2945,Ventura,California',
  '34.5362,-117.2928,Victorville,California',
  '36.3301,-119.2966,Visalia,California',
  '37.9101,-122.0652,Walnut Creek,California',
  '33.9386,-118.2380,Watts,California',
  '34.0686,-117.9390,West Covina,California',
  '33.9792,-118.0328,Whittier,California',
  '38.6785,-121.7733,Woodland,California',
  '33.8885,-117.8133,Yorba Linda,California',
  '39.1404,-121.6169,Yuba City,California',
  '37.4694,-105.8700,Alamosa,Colorado',
  '39.1911,-106.8175,Aspen,Colorado',
  '39.7294,-104.8319,Aurora,Colorado',
  '40.0150,-105.2705,Boulder,Colorado',
  '39.4817,-106.0384,Breckenridge,Colorado',
  '39.9853,-104.8205,Brighton,Colorado',
  '38.4494,-105.2253,Canon City,Colorado',
  '39.8019,-105.5142,Central City,Colorado',
  '39.5501,-105.7821,Climax,Colorado',
  '38.8339,-104.8214,Colorado Springs,Colorado',
  '37.3489,-108.5859,Cortez,Colorado',
  '38.7467,-105.1783,Cripple Creek,Colorado',
  '39.7392,-104.9903,Denver,Colorado',
  '37.2753,-107.8801,Durango,Colorado',
  '39.6478,-104.9878,Englewood,Colorado',
  '40.3772,-105.5217,Estes Park,Colorado',
  '40.5853,-105.0844,Fort Collins,Colorado',
  '40.2503,-103.8000,Fort Morgan,Colorado',
  '39.7061,-105.6975,Georgetown,Colorado',
  '39.5505,-107.3248,Glenwood Springs,Colorado',
  '39.7555,-105.2211,Golden,Colorado',
  '39.0639,-108.5506,Grand Junction,Colorado',
  '40.4233,-104.7091,Greeley,Colorado',
  '38.5458,-106.9253,Gunnison,Colorado',
  '37.9850,-103.5438,La Junta,Colorado',
  '39.2508,-106.2925,Leadville,Colorado',
  '39.6133,-105.0166,Littleton,Colorado',
  '40.1672,-105.1019,Longmont,Colorado',
  '40.3955,-105.0746,Loveland,Colorado',
  '38.4783,-107.8762,Montrose,Colorado',
  '38.0228,-107.6714,Ouray,Colorado',
  '37.2694,-107.0098,Pagosa Springs,Colorado',
  '38.2544,-104.6091,Pueblo,Colorado',
  '37.8119,-107.6645,Silverton,Colorado',
  '40.4850,-106.8317,Steamboat Springs,Colorado',
  '40.6255,-103.2077,Sterling,Colorado',
  '37.9375,-107.8123,Telluride,Colorado',
  '37.1695,-104.5005,Trinidad,Colorado',
  '39.6433,-106.3781,Vail,Colorado',
  '37.6227,-104.7804,Walsenburg,Colorado',
  '39.8367,-105.0372,Westminster,Colorado',
  '41.3462,-73.0790,Ansonia,Connecticut',
  '41.6215,-72.7457,Berlin,Connecticut',
  '41.8265,-72.7301,Bloomfield,Connecticut',
  '41.2799,-72.8141,Branford,Connecticut',
  '41.1792,-73.1894,Bridgeport,Connecticut',
  '41.6718,-72.9493,Bristol,Connecticut',
  '41.7847,-72.3393,Coventry,Connecticut',
  '41.3948,-73.4540,Danbury,Connecticut',
  '41.0772,-73.4687,Darien,Connecticut',
  '41.3207,-73.0890,Derby,Connecticut',
  '41.7634,-72.6128,East Hartford,Connecticut',
  '41.2842,-72.8685,East Haven,Connecticut',
  '42.0027,-72.5441,Enfield,Connecticut',
  '41.1408,-73.2613,Fairfield,Connecticut',
  '41.7360,-72.7950,Farmington,Connecticut',
  '41.0262,-73.6282,Greenwich,Connecticut',
  '41.3497,-72.0791,Groton,Connecticut',
  '41.2889,-72.6744,Guilford,Connecticut',
  '41.3839,-72.9026,Hamden,Connecticut',
  '41.7658,-72.6734,Hartford,Connecticut',
  '41.6363,-72.2126,Lebanon,Connecticut',
  '41.7473,-73.1887,Litchfield,Connecticut',
  '41.7759,-72.5215,Manchester,Connecticut',
  '41.7885,-72.2293,Mansfield,Connecticut',
  '41.5382,-72.8070,Meriden,Connecticut',
  '41.5623,-72.6506,Middletown,Connecticut',
  '41.2307,-73.0640,Milford,Connecticut',
  '41.3543,-71.9665,Mystic,Connecticut',
  '41.4859,-73.0507,Naugatuck,Connecticut',
  '41.6612,-72.7795,New Britain,Connecticut',
  '41.3083,-72.9279,New Haven,Connecticut',
  '41.3557,-72.0995,New London,Connecticut',
  '41.3909,-72.8595,North Haven,Connecticut',
  '41.1177,-73.4082,Norwalk,Connecticut',
  '41.5243,-72.0759,Norwich,Connecticut',
  '41.2960,-72.3845,Old Saybrook,Connecticut',
  '41.2786,-73.0265,Orange,Connecticut',
  '41.3971,-73.0748,Seymour,Connecticut',
  '41.3165,-73.0932,Shelton,Connecticut',
  '41.8540,-72.8279,Simsbury,Connecticut',
  '41.5965,-72.8776,Southington,Connecticut',
  '41.0534,-73.5387,Stamford,Connecticut',
  '41.3359,-71.9059,Stonington,Connecticut',
  '41.1845,-73.1332,Stratford,Connecticut',
  '41.8003,-73.1212,Torrington,Connecticut',
  '41.4570,-72.8231,Wallingford,Connecticut',
  '41.5582,-73.0515,Waterbury,Connecticut',
  '41.3542,-72.1648,Waterford,Connecticut',
  '41.6062,-73.1183,Watertown,Connecticut',
  '41.7621,-72.7420,West Hartford,Connecticut',
  '41.2705,-72.9470,West Haven,Connecticut',
  '41.1415,-73.3579,Westport,Connecticut',
  '41.7064,-72.6596,Wethersfield,Connecticut',
  '41.7107,-72.2081,Willimantic,Connecticut',
  '41.6998,-72.1570,Windham,Connecticut',
  '41.8526,-72.6437,Windsor,Connecticut',
  '41.9243,-72.6454,Windsor Locks,Connecticut',
  '41.9238,-73.0600,Winsted,Connecticut',
  '39.1582,-75.5244,Dover,Delaware',
  '38.7746,-75.1393,Lewes,Delaware',
  '38.9126,-75.4280,Milford,Delaware',
  '39.6620,-75.5668,New Castle,Delaware',
  '39.6837,-75.7497,Newark,Delaware',
  '39.2998,-75.6046,Smyrna,Delaware',
  '39.7447,-75.5484,Wilmington,Delaware',
  '29.7258,-84.9832,Apalachicola,Florida',
  '27.8964,-81.8431,Bartow,Florida',
  '26.6845,-80.6676,Belle Glade,Florida',
  '26.3683,-80.1289,Boca Raton,Florida',
  '27.4989,-82.5748,Bradenton,Florida',
  '26.5629,-81.9495,Cape Coral,Florida',
  '27.9659,-82.8001,Clearwater,Florida',
  '28.3200,-80.6076,Cocoa Beach,Florida',
  '28.3294,-80.7389,Cocoa-Rockledge,Florida',
  '25.7492,-80.2635,Coral Gables,Florida',
  '29.2108,-81.0228,Daytona Beach,Florida',
  '29.0283,-81.3031,De Land,Florida',
  '26.3184,-80.0998,Deerfield Beach,Florida',
  '26.4615,-80.0728,Delray Beach,Florida',
  '30.6697,-81.4626,Fernandina Beach,Florida',
  '26.1224,-80.1373,Fort Lauderdale,Florida',
  '26.6406,-81.8723,Fort Myers,Florida',
  '27.4467,-80.3256,Fort Pierce,Florida',
  '30.4201,-86.6170,Fort Walton Beach,Florida',
  '29.6516,-82.3248,Gainesville,Florida',
  '25.9812,-80.1484,Hallandale Beach,Florida',
  '25.8576,-80.2781,Hialeah,Florida',
  '26.0112,-80.1495,Hollywood,Florida',
  '25.4687,-80.4776,Homestead,Florida',
  '30.3322,-81.6557,Jacksonville,Florida',
  '24.5551,-81.7800,Key West,Florida',
  '30.1897,-82.6393,Lake City,Florida',
  '27.9014,-81.5859,Lake Wales,Florida',
  '28.0395,-81.9498,Lakeland,Florida',
  '27.9095,-82.7873,Largo,Florida',
  '28.0836,-80.6081,Melbourne,Florida',
  '25.7617,-80.1918,Miami,Florida',
  '25.7907,-80.1300,Miami Beach,Florida',
  '26.1420,-81.7948,Naples,Florida',
  '29.0258,-80.9270,New Smyrna Beach,Florida',
  '29.1872,-82.1401,Ocala,Florida',
  '28.5384,-81.3789,Orlando,Florida',
  '29.2858,-81.0559,Ormond Beach,Florida',
  '29.6486,-81.6376,Palatka,Florida',
  '28.0345,-80.5887,Palm Bay,Florida',
  '26.7056,-80.0364,Palm Beach,Florida',
  '30.1588,-85.6602,Panama City,Florida',
  '30.4213,-87.2169,Pensacola,Florida',
  '26.2379,-80.1248,Pompano Beach,Florida',
  '29.9012,-81.3124,Saint Augustine,Florida',
  '27.7676,-82.6403,Saint Petersburg,Florida',
  '28.8029,-81.2695,Sanford,Florida',
  '27.3365,-82.5310,Sarasota,Florida',
  '27.4956,-81.4409,Sebring,Florida',
  '30.4383,-84.2807,Tallahassee,Florida',
  '27.9506,-82.4572,Tampa,Florida',
  '28.1461,-82.7568,Tarpon Springs,Florida',
  '28.6122,-80.8076,Titusville,Florida',
  '27.0998,-82.4543,Venice,Florida',
  '26.7153,-80.0534,West Palm Beach,Florida',
  '30.3297,-82.7590,White Springs,Florida',
  '28.0222,-81.7329,Winter Haven,Florida',
  '28.6000,-81.3392,Winter Park,Florida',
  '31.5785,-84.1557,Albany,Georgia',
  '32.0724,-84.2327,Americus,Georgia',
  '32.1960,-84.1399,Andersonville,Georgia',
  '33.9519,-83.3576,Athens,Georgia',
  '33.7488,-84.3877,Atlanta,Georgia',
  '33.4735,-82.0105,Augusta,Georgia',
  '30.9038,-84.5755,Bainbridge,Georgia',
  '34.8761,-83.9584,Blairsville,Georgia',
  '31.1500,-81.4915,Brunswick,Georgia',
  '34.5026,-84.9511,Calhoun,Georgia',
  '33.5801,-85.0766,Carrollton,Georgia',
  '32.4610,-84.9877,Columbus,Georgia',
  '34.5261,-83.9844,Dahlonega,Georgia',
  '34.7698,-84.9702,Dalton,Georgia',
  '31.3702,-81.4340,Darien,Georgia',
  '33.7748,-84.2963,Decatur,Georgia',
  '31.5088,-82.8499,Douglas,Georgia',
  '33.6796,-84.4394,East Point,Georgia',
  '31.7149,-83.2527,Fitzgerald,Georgia',
  '32.5538,-83.8874,Fort Valley,Georgia',
  '34.2979,-83.8241,Gainesville,Georgia',
  '33.0362,-85.0322,La Grange,Georgia',
  '32.8407,-83.6324,Macon,Georgia',
  '33.9526,-84.5499,Marietta,Georgia',
  '33.0801,-83.2321,Milledgeville,Georgia',
  '32.0341,-84.3927,Plains,Georgia',
  '34.2570,-85.1647,Rome,Georgia',
  '32.0809,-81.0912,Savannah,Georgia',
  '34.5773,-83.3324,Toccoa,Georgia',
  '30.8327,-83.2785,Valdosta,Georgia',
  '32.8904,-84.6810,Warm Springs,Georgia',
  '32.6130,-83.6242,Warner Robins,Georgia',
  '33.7368,-82.7393,Washington,Georgia',
  '31.2136,-82.3540,Waycross,Georgia',
  '22.2059,-159.4976,Hanalei,Hawaii',
  '19.7216,-155.0849,Hilo,Hawaii',
  '19.4574,-155.8907,Honaunau,Hawaii',
  '21.3099,-157.8581,Honolulu,Hawaii',
  '20.8893,-156.4729,Kahului,Hawaii',
  '21.4029,-157.7996,Kaneohe,Hawaii',
  '22.0947,-159.3246,Kapaa,Hawaii',
  '20.0382,-155.8291,Kawaihae,Hawaii',
  '20.8824,-156.6816,Lahaina,Hawaii',
  '21.6470,-157.9213,Laie,Hawaii',
  '21.4960,-158.0298,Wahiawa,Hawaii',
  '20.8869,-156.5048,Wailuku,Hawaii',
  '20.0204,-155.6689,Waimea,Hawaii',
  '43.1905,-112.3450,Blackfoot,Idaho',
  '43.6150,-116.2023,Boise,Idaho',
  '48.6913,-116.3163,Bonners Ferry,Idaho',
  '43.6629,-116.6874,Caldwell,Idaho',
  "47.6735,-116.7812,Coeur d'Alene,Idaho",
  '43.8285,-115.8346,Idaho City,Idaho',
  '43.4927,-112.0408,Idaho Falls,Idaho',
  '47.5383,-116.1193,Kellogg,Idaho',
  '46.4004,-117.0012,Lewiston,Idaho',
  '46.7324,-117.0002,Moscow,Idaho',
  '43.5788,-116.5598,Nampa,Idaho',
  '42.8621,-112.4506,Pocatello,Idaho',
  '48.1807,-116.9094,Priest River,Idaho',
  '43.8231,-111.7924,Rexburg,Idaho',
  '43.6971,-114.3517,Sun Valley,Idaho',
  '42.5558,-114.4701,Twin Falls,Idaho',
  '38.8906,-90.1843,Alton,Illinois',
  '42.0884,-87.9806,Arlington Heights,Illinois',
  '39.7148,-88.4723,Arthur,Illinois',
  '41.7606,-88.3201,Aurora,Illinois',
  '38.5201,-89.9840,Belleville,Illinois',
  '42.2639,-88.8443,Belvidere,Illinois',
  '40.4842,-88.9937,Bloomington,Illinois',
  '41.8239,-87.8517,Brookfield,Illinois',
  '38.5709,-90.1901,Cahokia,Illinois',
  '37.0053,-89.1765,Cairo,Illinois',
  '41.6156,-87.5295,Calumet City,Illinois',
  '40.5581,-90.0351,Canton,Illinois',
  '37.7273,-89.2168,Carbondale,Illinois',
  '39.2798,-89.8818,Carlinville,Illinois',
  '40.4164,-91.1363,Carthage,Illinois',
  '38.5285,-89.1317,Centralia,Illinois',
  '40.1164,-88.2434,Champaign,Illinois',
  '39.4961,-88.1762,Charleston,Illinois',
  '37.9137,-89.8221,Chester,Illinois',
  '41.8781,-87.6298,Chicago,Illinois',
  '41.5061,-87.6356,Chicago Heights,Illinois',
  '41.8456,-87.7539,Cicero,Illinois',
  '38.6703,-89.9845,Collinsville,Illinois',
  '40.1245,-87.6300,Danville,Illinois',
  '39.8403,-88.9548,Decatur,Illinois',
  '41.9295,-88.7499,DeKalb,Illinois',
  '42.0334,-87.8834,Des Plaines,Illinois',
  '41.8389,-89.4795,Dixon,Illinois',
  '41.5009,-90.4443,East Moline,Illinois',
  '38.6245,-90.1506,East Saint Louis,Illinois',
  '39.1200,-88.5434,Effingham,Illinois',
  '42.0354,-88.2826,Elgin,Illinois',
  '41.8995,-87.9403,Elmhurst,Illinois',
  '42.0451,-87.6877,Evanston,Illinois',
  '42.2967,-89.6212,Freeport,Illinois',
  '42.4167,-90.4290,Galena,Illinois',
  '40.9478,-90.3712,Galesburg,Illinois',
  '41.8775,-88.0670,Glen Ellyn,Illinois',
  '42.0778,-87.8223,Glenview,Illinois',
  '38.7014,-90.1487,Granite City,Illinois',
  '37.7384,-88.5406,Harrisburg,Illinois',
  '37.8031,-89.0276,Herrin,Illinois',
  '42.1817,-87.8003,Highland Park,Illinois',
  '39.7339,-90.2290,Jacksonville,Illinois',
  '41.5250,-88.0817,Joliet,Illinois',
  '41.1200,-87.8612,Kankakee,Illinois',
  '37.9214,-89.9132,Kaskaskia,Illinois',
  '41.2456,-89.9248,Kewanee,Illinois',
  '41.3622,-89.0418,La Salle,Illinois',
  '42.2586,-87.8406,Lake Forest,Illinois',
  '42.2831,-87.9531,Libertyville,Illinois',
  '40.1484,-89.3648,Lincoln,Illinois',
  '41.8011,-88.0748,Lisle,Illinois',
  '41.8800,-88.0078,Lombard,Illinois',
  '40.4592,-90.6718,Macomb,Illinois',
  '39.4831,-88.3728,Mattoon,Illinois',
  '41.5067,-90.5151,Moline,Illinois',
  '40.9114,-90.6474,Monmouth,Illinois',
  '38.3173,-88.9031,Mount Vernon,Illinois',
  '42.2631,-88.0040,Mundelein,Illinois',
  '41.7508,-88.1535,Naperville,Illinois',
  '40.5500,-91.3849,Nauvoo,Illinois',
  '40.5142,-88.9906,Normal,Illinois',
  '42.3256,-87.8412,North Chicago,Illinois',
  '41.8850,-87.7845,Oak Park,Illinois',
  '42.0148,-89.3323,Oregon,Illinois',
  '41.3456,-88.8426,Ottawa,Illinois',
  '42.1103,-88.0342,Palatine,Illinois',
  '41.4914,-87.6745,Park Forest,Illinois',
  '42.0111,-87.8406,Park Ridge,Illinois',
  '40.5675,-89.6407,Pekin,Illinois',
  '40.6936,-89.5890,Peoria,Illinois',
  '40.0117,-89.8482,Petersburg,Illinois',
  '40.8809,-88.6298,Pontiac,Illinois',
  '39.9356,-91.4099,Quincy,Illinois',
  '40.3084,-88.1559,Rantoul,Illinois',
  '41.8940,-87.8198,River Forest,Illinois',
  '41.5095,-90.5787,Rock Island,Illinois',
  '42.2711,-89.0940,Rockford,Illinois',
  '38.6270,-88.9456,Salem,Illinois',
  '37.7131,-88.1867,Shawneetown,Illinois',
  '42.0324,-87.7416,Skokie,Illinois',
  '41.6009,-87.6070,South Holland,Illinois',
  '39.7817,-89.6501,Springfield,Illinois',
  '41.1209,-88.8354,Streator,Illinois',
  '41.7881,-87.8103,Summit,Illinois',
  '40.1106,-88.2073,Urbana,Illinois',
  '38.9606,-89.0937,Vandalia,Illinois',
  '39.5009,-89.7679,Virden,Illinois',
  '42.3636,-87.8448,Waukegan,Illinois',
  '41.8661,-88.1070,Wheaton,Illinois',
  '42.0723,-87.7228,Wilmette,Illinois',
  '42.1081,-87.7359,Winnetka,Illinois',
  '38.8612,-90.0976,Wood River,Illinois',
  '42.4461,-87.8329,Zion,Illinois',
  '40.1053,-85.6803,Anderson,Indiana',
  '38.8612,-86.4872,Bedford,Indiana',
  '39.1653,-86.5264,Bloomington,Indiana',
  '39.2014,-85.9214,Columbus,Indiana',
  '39.6412,-85.1411,Connersville,Indiana',
  '38.2120,-86.1219,Corydon,Indiana',
  '40.0412,-86.8745,Crawfordsville,Indiana',
  '41.6392,-87.4548,East Chicago,Indiana',
  '41.6874,-85.9735,Elkhart,Indiana',
  '40.2770,-85.8419,Elwood,Indiana',
  '37.9716,-87.5711,Evansville,Indiana',
  '41.0793,-85.1394,Fort Wayne,Indiana',
  '38.5489,-86.6200,French Lick,Indiana',
  '41.6020,-87.3372,Gary,Indiana',
  '40.5920,-84.9588,Geneva,Indiana',
  '41.5892,-85.8346,Goshen,Indiana',
  '39.7850,-85.7694,Greenfield,Indiana',
  '41.5834,-87.5000,Hammond,Indiana',
  '41.5323,-87.2550,Hobart,Indiana',
  '40.8831,-85.4975,Huntington,Indiana',
  '39.7684,-86.1581,Indianapolis,Indiana',
  '38.2776,-85.7372,Jeffersonville,Indiana',
  '40.4864,-86.1336,Kokomo,Indiana',
  '40.4167,-86.8753,Lafayette,Indiana',
  '38.7359,-85.3800,Madison,Indiana',
  '40.5584,-85.6591,Marion,Indiana',
  '41.7075,-86.8950,Michigan City,Indiana',
  '41.6620,-86.1586,Mishawaka,Indiana',
  '40.1934,-85.3864,Muncie,Indiana',
  '41.4426,-85.9942,Nappanee,Indiana',
  '39.2073,-86.2470,Nashville,Indiana',
  '38.2856,-85.8241,New Albany,Indiana',
  '39.9289,-85.3702,New Castle,Indiana',
  '38.1298,-87.9350,New Harmony,Indiana',
  '40.7537,-86.0689,Peru,Indiana',
  '41.3437,-86.3097,Plymouth,Indiana',
  '39.8289,-84.8902,Richmond,Indiana',
  '38.1201,-86.9142,Santa Claus,Indiana',
  '39.5214,-85.7769,Shelbyville,Indiana',
  '41.6764,-86.2520,South Bend,Indiana',
  '39.4667,-87.4139,Terre Haute,Indiana',
  '41.4731,-87.0611,Valparaiso,Indiana',
  '38.6773,-87.5286,Vincennes,Indiana',
  '40.7978,-85.8205,Wabash,Indiana',
  '40.4259,-86.9081,West Lafayette,Indiana',
  '41.8000,-91.8681,Amana Colonies,Iowa',
  '42.0308,-93.6319,Ames,Iowa',
  '42.0597,-93.8802,Boone,Iowa',
  '40.8077,-91.1129,Burlington,Iowa',
  '42.5349,-92.4453,Cedar Falls,Iowa',
  '41.9779,-91.6656,Cedar Rapids,Iowa',
  '43.0664,-92.6724,Charles City,Iowa',
  '42.7496,-95.5515,Cherokee,Iowa',
  '41.8445,-90.1887,Clinton,Iowa',
  '41.2619,-95.8608,Council Bluffs,Iowa',
  '41.5236,-90.5776,Davenport,Iowa',
  '41.5868,-93.6250,Des Moines,Iowa',
  '42.5063,-90.6677,Dubuque,Iowa',
  '43.4013,-94.8392,Estherville,Iowa',
  '41.0076,-91.9637,Fairfield,Iowa',
  '42.4975,-94.1680,Fort Dodge,Iowa',
  '41.7434,-92.7232,Grinnell,Iowa',
  '41.3580,-93.5574,Indianola,Iowa',
  '41.6578,-91.5346,Iowa City,Iowa',
  '40.4045,-91.3964,Keokuk,Iowa',
  '43.1536,-93.2010,Mason City,Iowa',
  '40.9717,-91.5488,Mount Pleasant,Iowa',
  '41.4245,-91.0432,Muscatine,Iowa',
  '41.7033,-93.0574,Newton,Iowa',
  '41.2917,-92.6494,Oskaloosa,Iowa',
  '41.0160,-92.4083,Ottumwa,Iowa',
  '42.4963,-96.4049,Sioux City,Iowa',
  '42.4928,-92.3426,Waterloo,Iowa',
  '42.4694,-93.8159,Webster City,Iowa',
  '41.5639,-93.7594,West Des Moines,Iowa',
  '38.9172,-97.2139,Abilene,Kansas',
  '37.0620,-97.0384,Arkansas City,Kansas',
  '39.5631,-95.1216,Atchison,Kansas',
  '37.6792,-95.4572,Chanute,Kansas',
  '37.0373,-95.6164,Coffeyville,Kansas',
  '38.6611,-96.4919,Council Grove,Kansas',
  '37.7528,-100.0171,Dodge City,Kansas',
  '38.4039,-96.1817,Emporia,Kansas',
  '37.8398,-94.7083,Fort Scott,Kansas',
  '37.9717,-100.8727,Garden City,Kansas',
  '38.3645,-98.7648,Great Bend,Kansas',
  '38.8792,-99.3268,Hays,Kansas',
  '38.0608,-97.9298,Hutchinson,Kansas',
  '37.2242,-95.7083,Independence,Kansas',
  '39.0286,-96.8314,Junction City,Kansas',
  '39.1155,-94.6268,Kansas City,Kansas',
  '38.9717,-95.2353,Lawrence,Kansas',
  '39.3104,-94.9279,Leavenworth,Kansas',
  '37.0431,-100.9210,Liberal,Kansas',
  '39.1836,-96.5717,Manhattan,Kansas',
  '38.3708,-97.6642,McPherson,Kansas',
  '37.2811,-98.5804,Medicine Lodge,Kansas',
  '38.0467,-97.3450,Newton,Kansas',
  '38.8814,-94.8191,Olathe,Kansas',
  '38.4972,-94.9505,Osawatomie,Kansas',
  '38.6158,-95.2686,Ottawa,Kansas',
  '38.9822,-94.6708,Overland Park,Kansas',
  '37.4109,-94.7050,Pittsburg,Kansas',
  '38.8403,-97.6114,Salina,Kansas',
  '39.0228,-94.7152,Shawnee,Kansas',
  '39.7334,-98.7481,Smith Center,Kansas',
  '39.0473,-95.6752,Topeka,Kansas',
  '37.6872,-97.3301,Wichita,Kansas',
  '38.4784,-82.6379,Ashland,Kentucky',
  '36.8665,-83.8888,Barbourville,Kentucky',
  '37.8092,-85.4669,Bardstown,Kentucky',
  '37.5687,-84.2963,Berea,Kentucky',
  '37.9084,-84.2719,Boonesborough,Kentucky',
  '36.9685,-86.4808,Bowling Green,Kentucky',
  '37.3434,-85.3419,Campbellsville,Kentucky',
  '39.0837,-84.5086,Covington,Kentucky',
  '37.6456,-84.7722,Danville,Kentucky',
  '37.7031,-85.8649,Elizabethtown,Kentucky',
  '38.2009,-84.8733,Frankfort,Kentucky',
  '36.8431,-83.3218,Harlan,Kentucky',
  '37.7623,-84.8433,Harrodsburg,Kentucky',
  '37.2495,-83.1932,Hazard,Kentucky',
  '37.8362,-87.5900,Henderson,Kentucky',
  '37.5739,-85.7400,Hodgenville,Kentucky',
  '36.8656,-87.4886,Hopkinsville,Kentucky',
  '38.0406,-84.5037,Lexington,Kentucky',
  '38.2527,-85.7585,Louisville,Kentucky',
  '36.7417,-88.6367,Mayfield,Kentucky',
  '38.6412,-83.7444,Maysville,Kentucky',
  '36.6073,-83.7143,Middlesboro,Kentucky',
  '39.0914,-84.4958,Newport,Kentucky',
  '37.7719,-87.1112,Owensboro,Kentucky',
  '37.0834,-88.6000,Paducah,Kentucky',
  '38.2098,-84.2530,Paris,Kentucky',
  '37.7479,-84.2947,Richmond,Kentucky',
  '29.9747,-92.1343,Abbeville,Louisiana',
  '31.3113,-92.4451,Alexandria,Louisiana',
  '32.7562,-91.8723,Bastrop,Louisiana',
  '30.4515,-91.1871,Baton Rouge,Louisiana',
  '30.7910,-89.8487,Bogalusa,Louisiana',
  '32.5160,-93.7321,Bossier City,Louisiana',
  '29.9146,-90.0540,Gretna,Louisiana',
  '29.5958,-90.7195,Houma,Louisiana',
  '30.2241,-92.0198,Lafayette,Louisiana',
  '30.2266,-93.2174,Lake Charles,Louisiana',
  '32.5093,-92.1193,Monroe,Louisiana',
  '29.6994,-91.2068,Morgan City,Louisiana',
  '31.7607,-93.0863,Natchitoches,Louisiana',
  '30.0035,-91.8187,New Iberia,Louisiana',
  '29.9511,-90.0715,New Orleans,Louisiana',
  '30.5335,-92.0815,Opelousas,Louisiana',
  '32.5232,-92.6379,Ruston,Louisiana',
  '30.1231,-91.8295,Saint Martinville,Louisiana',
  '32.5252,-93.7502,Shreveport,Louisiana',
  '29.7958,-90.8229,Thibodaux,Louisiana',
  '44.0979,-70.2312,Auburn,Maine',
  '44.3106,-69.7795,Augusta,Maine',
  '44.8016,-68.7712,Bangor,Maine',
  '44.3876,-68.2039,Bar Harbor,Maine',
  '43.9106,-69.8206,Bath,Maine',
  '44.4259,-69.0064,Belfast,Maine',
  '43.4926,-70.4534,Biddeford,Maine',
  '43.8523,-69.6281,Boothbay Harbor,Maine',
  '43.9140,-69.9670,Brunswick,Maine',
  '45.1890,-67.2786,Calais,Maine',
  '46.8640,-67.9980,Caribou,Maine',
  '44.3889,-68.7990,Castine,Maine',
  '44.9062,-66.9900,Eastport,Maine',
  '44.5434,-68.4195,Ellsworth,Maine',
  '44.6705,-70.1512,Farmington,Maine',
  '47.2587,-68.5895,Fort Kent,Maine',
  '44.2301,-69.7753,Gardiner,Maine',
  '46.1262,-67.8402,Houlton,Maine',
  '43.3617,-70.4767,Kennebunkport,Maine',
  '43.0881,-70.7361,Kittery,Maine',
  '44.1004,-70.2148,Lewiston,Maine',
  '44.8586,-66.9836,Lubec,Maine',
  '44.7151,-67.4614,Machias,Maine',
  '44.8831,-68.6719,Orono,Maine',
  '43.6591,-70.2568,Portland,Maine',
  '46.6812,-68.0159,Presque Isle,Maine',
  '44.1037,-69.1089,Rockland,Maine',
  '44.5537,-70.5509,Rumford,Maine',
  '43.5009,-70.4428,Saco,Maine',
  '43.5902,-70.3345,Scarborough,Maine',
  '44.5520,-69.6317,Waterville,Maine',
  '43.1617,-70.6483,York,Maine',
  '39.5096,-76.1641,Aberdeen,Maryland',
  '38.9784,-76.4922,Annapolis,Maryland',
  '39.2904,-76.6122,Baltimore,Maryland',
  '38.9865,-77.0885,Bethesda-Chevy Chase,Maryland',
  '39.0068,-76.7791,Bowie,Maryland',
  '38.5632,-76.0788,Cambridge,Maryland',
  '39.2721,-76.7319,Catonsville,Maryland',
  '38.9897,-76.9378,College Park,Maryland',
  '39.2037,-76.8610,Columbia,Maryland',
  '39.6529,-78.7625,Cumberland,Maryland',
  '38.7743,-76.0763,Easton,Maryland',
  '39.6068,-75.8333,Elkton,Maryland',
  '39.7045,-77.3269,Emmitsburg,Maryland',
  '39.4143,-77.4105,Frederick,Maryland',
  '39.0046,-76.8755,Greenbelt,Maryland',
  '39.6418,-77.7200,Hagerstown,Maryland',
  '38.9559,-76.9455,Hyattsville,Maryland',
  '39.0993,-76.8483,Laurel,Maryland',
  '39.4079,-79.4067,Oakland,Maryland',
  '38.3365,-75.0849,Ocean City,Maryland',
  '39.0840,-77.1528,Rockville,Maryland',
  '38.1872,-76.4343,Saint Marys City,Maryland',
  '38.3607,-75.5994,Salisbury,Maryland',
  '38.9907,-77.0261,Silver Spring,Maryland',
  '38.9779,-77.0075,Takoma Park,Maryland',
  '39.4015,-76.6019,Towson,Maryland',
  '39.5754,-76.9958,Westminster,Maryland',
  '42.1048,-70.9453,Abington,Massachusetts',
  '42.6242,-73.1174,Adams,Massachusetts',
  '42.8584,-70.9300,Amesbury,Massachusetts',
  '42.3868,-72.5301,Amherst,Massachusetts',
  '42.6583,-71.1368,Andover,Massachusetts',
  '42.4154,-71.1565,Arlington,Massachusetts',
  '42.5959,-72.2267,Athol,Massachusetts',
  '41.9445,-71.2856,Attleboro,Massachusetts',
  '41.7003,-70.3002,Barnstable,Massachusetts',
  '42.4906,-71.2760,Bedford,Massachusetts',
  '42.5584,-70.8800,Beverly,Massachusetts',
  '42.3601,-71.0589,Boston,Massachusetts',
  '41.7413,-70.5989,Bourne,Massachusetts',
  '42.2079,-71.0040,Braintree,Massachusetts',
  '42.0834,-71.0184,Brockton,Massachusetts',
  '42.3318,-71.1212,Brookline,Massachusetts',
  '42.3736,-71.1097,Cambridge,Massachusetts',
  '42.1584,-71.1448,Canton,Massachusetts',
  '42.3787,-71.0616,Charlestown,Massachusetts',
  '42.5998,-71.3673,Chelmsford,Massachusetts',
  '42.3918,-71.0328,Chelsea,Massachusetts',
  '42.1487,-72.6079,Chicopee,Massachusetts',
  '42.4168,-71.6829,Clinton,Massachusetts',
  '42.2418,-70.8037,Cohasset,Massachusetts',
  '42.4604,-71.3489,Concord,Massachusetts',
  '42.5750,-70.9321,Danvers,Massachusetts',
  '41.6130,-70.9705,Dartmouth,Massachusetts',
  '42.2436,-71.1677,Dedham,Massachusetts',
  '41.7353,-70.1939,Dennis,Massachusetts',
  '42.0418,-70.6723,Duxbury,Massachusetts',
  '41.8300,-69.9740,Eastham,Massachusetts',
  '41.3890,-70.5134,Edgartown,Massachusetts',
  '42.4084,-71.0537,Everett,Massachusetts',
  '41.6376,-70.9036,Fairhaven,Massachusetts',
  '41.7015,-71.1550,Fall River,Massachusetts',
  '41.5532,-70.6086,Falmouth,Massachusetts',
  '42.5834,-71.8023,Fitchburg,Massachusetts',
  '42.2793,-71.4162,Framingham,Massachusetts',
  '42.6159,-70.6620,Gloucester,Massachusetts',
  '42.1960,-73.3620,Great Barrington,Massachusetts',
  '42.5879,-72.5994,Greenfield,Massachusetts',
  '42.6112,-71.5745,Groton,Massachusetts',
  '41.6716,-70.0622,Harwich,Massachusetts',
  '42.7762,-71.0773,Haverhill,Massachusetts',
  '42.2418,-70.8898,Hingham,Massachusetts',
  '42.2043,-72.6162,Holyoke,Massachusetts',
  '41.6525,-70.2881,Hyannis,Massachusetts',
  '42.6792,-70.8412,Ipswich,Massachusetts',
  '42.7070,-71.1631,Lawrence,Massachusetts',
  '42.3565,-73.2849,Lenox,Massachusetts',
  '42.5251,-71.7598,Leominster,Massachusetts',
  '42.4473,-71.2272,Lexington,Massachusetts',
  '42.6334,-71.3162,Lowell,Massachusetts',
  '42.1601,-72.4759,Ludlow,Massachusetts',
  '42.4668,-70.9495,Lynn,Massachusetts',
  '42.4251,-71.0662,Malden,Massachusetts',
  '42.5000,-70.8578,Marblehead,Massachusetts',
  '42.3459,-71.5523,Marlborough,Massachusetts',
  '42.4184,-71.1062,Medford,Massachusetts',
  '42.2495,-71.0662,Milton,Massachusetts',
  '42.4266,-70.9223,Nahant,Massachusetts',
  '42.2775,-71.3468,Natick,Massachusetts',
  '41.6362,-70.9342,New Bedford,Massachusetts',
  '42.8126,-70.8773,Newburyport,Massachusetts',
  '42.3370,-71.2092,Newton,Massachusetts',
  '42.7009,-73.1087,North Adams,Massachusetts',
  '42.3251,-72.6412,Northampton,Massachusetts',
  '41.9668,-71.1870,Norton,Massachusetts',
  '42.1944,-71.1990,Norwood,Massachusetts',
  '42.5279,-70.9287,Peabody,Massachusetts',
  '42.4501,-73.2454,Pittsfield,Massachusetts',
  '41.9584,-70.6673,Plymouth,Massachusetts',
  '42.0547,-70.1846,Provincetown,Massachusetts',
  '42.2529,-71.0023,Quincy,Massachusetts',
  '42.1620,-71.0426,Randolph,Massachusetts',
  '42.4084,-71.0120,Revere,Massachusetts',
  '42.5197,-70.8955,Salem,Massachusetts',
  '41.7590,-70.4939,Sandwich,Massachusetts',
  '42.4651,-71.0110,Saugus,Massachusetts',
  '42.3876,-71.0995,Somerville,Massachusetts',
  '42.2592,-72.5748,South Hadley,Massachusetts',
  '42.1015,-72.5898,Springfield,Massachusetts',
  '42.2876,-73.3204,Stockbridge,Massachusetts',
  '42.1229,-71.1092,Stoughton,Massachusetts',
  '42.1084,-72.0787,Sturbridge,Massachusetts',
  '42.3834,-71.4162,Sudbury,Massachusetts',
  '41.9001,-71.0898,Taunton,Massachusetts',
  '42.6106,-71.2342,Tewksbury,Massachusetts',
  '42.0032,-70.0534,Truro,Massachusetts',
  '42.3709,-71.1828,Watertown,Massachusetts',
  '42.0501,-71.8801,Webster,Massachusetts',
  '42.2968,-71.2924,Wellesley,Massachusetts',
  '41.9305,-70.0310,Wellfleet,Massachusetts',
  '42.0190,-71.0078,West Bridgewater,Massachusetts',
  '42.1070,-72.6204,West Springfield,Massachusetts',
  '42.1251,-72.7495,Westfield,Massachusetts',
  '42.2181,-70.9410,Weymouth,Massachusetts',
  '42.0807,-70.9356,Whitman,Massachusetts',
  '42.7120,-73.2037,Williamstown,Massachusetts',
  '42.4793,-71.1523,Woburn,Massachusetts',
  '41.5265,-70.6731,Woods Hole,Massachusetts',
  '42.2626,-71.8023,Worcester,Massachusetts',
  '41.8975,-84.0372,Adrian,Michigan',
  '43.3790,-84.6602,Alma,Michigan',
  '42.2808,-83.7430,Ann Arbor,Michigan',
  '42.3212,-85.1797,Battle Creek,Michigan',
  '43.5945,-83.8889,Bay City,Michigan',
  '42.1167,-86.4542,Benton Harbor,Michigan',
  '42.5836,-83.2455,Bloomfield Hills,Michigan',
  '44.2520,-85.4012,Cadillac,Michigan',
  '45.3181,-85.2584,Charlevoix,Michigan',
  '45.6470,-84.4745,Cheboygan,Michigan',
  '42.3223,-83.1763,Dearborn,Michigan',
  '42.3314,-83.0458,Detroit,Michigan',
  '42.7370,-84.4839,East Lansing,Michigan',
  '42.4684,-82.9555,Eastpointe,Michigan',
  '42.2445,-83.1458,Ecorse,Michigan',
  '45.7452,-87.0646,Escanaba,Michigan',
  '43.0125,-83.6875,Flint,Michigan',
  '43.0631,-86.2284,Grand Haven,Michigan',
  '42.9634,-85.6681,Grand Rapids,Michigan',
  '44.6614,-84.7148,Grayling,Michigan',
  '42.3861,-82.9119,Grosse Pointe,Michigan',
  '47.1269,-88.5810,Hancock,Michigan',
  '42.4056,-83.0969,Highland Park,Michigan',
  '42.7875,-86.1089,Holland,Michigan',
  '47.1211,-88.5694,Houghton,Michigan',
  '44.6454,-85.7676,Interlochen,Michigan',
  '45.8202,-88.0660,Iron Mountain,Michigan',
  '46.4547,-90.1710,Ironwood,Michigan',
  '46.4885,-87.6676,Ishpeming,Michigan',
  '42.2459,-84.4013,Jackson,Michigan',
  '42.2917,-85.5872,Kalamazoo,Michigan',
  '42.7325,-84.5555,Lansing,Michigan',
  '42.3684,-83.3527,Livonia,Michigan',
  '43.9553,-86.4526,Ludington,Michigan',
  '45.7775,-84.7271,Mackinaw City,Michigan',
  '44.2444,-86.3243,Manistee,Michigan',
  '46.5436,-87.3954,Marquette,Michigan',
  '45.1078,-87.6143,Menominee,Michigan',
  '43.6156,-84.2472,Midland,Michigan',
  '41.9164,-83.3977,Monroe,Michigan',
  '42.5973,-82.8780,Mount Clemens,Michigan',
  '43.5978,-84.7675,Mount Pleasant,Michigan',
  '43.2342,-86.2484,Muskegon,Michigan',
  '41.8298,-86.2542,Niles,Michigan',
  '45.3733,-84.9553,Petoskey,Michigan',
  '42.6389,-83.2910,Pontiac,Michigan',
  '42.9709,-82.4249,Port Huron,Michigan',
  '42.4895,-83.1446,Royal Oak,Michigan',
  '43.4195,-83.9508,Saginaw,Michigan',
  '45.8756,-84.7323,Saint Ignace,Michigan',
  '42.0939,-86.4895,Saint Joseph,Michigan',
  '46.4977,-84.3476,Sault Sainte Marie,Michigan',
  '44.7631,-85.6206,Traverse City,Michigan',
  '42.1395,-83.1783,Trenton,Michigan',
  '42.5145,-83.0147,Warren,Michigan',
  '42.2142,-83.1499,Wyandotte,Michigan',
  '42.2411,-83.6130,Ypsilanti,Michigan',
  '43.6478,-93.3687,Albert Lea,Minnesota',
  '45.8848,-95.3777,Alexandria,Minnesota',
  '43.6666,-92.9746,Austin,Minnesota',
  '47.4716,-94.8827,Bemidji,Minnesota',
  '44.8408,-93.2983,Bloomington,Minnesota',
  '46.3527,-94.2020,Brainerd,Minnesota',
  '47.7746,-96.6094,Crookston,Minnesota',
  '46.7867,-92.1005,Duluth,Minnesota',
  '47.9032,-91.8671,Ely,Minnesota',
  '47.4624,-92.5399,Eveleth,Minnesota',
  '44.2950,-93.2688,Faribault,Minnesota',
  '46.2835,-96.0778,Fergus Falls,Minnesota',
  '44.7443,-92.8514,Hastings,Minnesota',
  '47.4272,-92.9377,Hibbing,Minnesota',
  '48.6023,-93.4041,International Falls,Minnesota',
  '45.9806,-94.3694,Little Falls,Minnesota',
  '44.1636,-93.9994,Mankato,Minnesota',
  '44.9778,-93.2650,Minneapolis,Minnesota',
  '46.8738,-96.7678,Moorhead,Minnesota',
  '44.3144,-94.4593,New Ulm,Minnesota',
  '44.4583,-93.1616,Northfield,Minnesota',
  '44.0879,-93.2260,Owatonna,Minnesota',
  '43.9986,-96.3128,Pipestone,Minnesota',
  '44.5661,-92.5370,Red Wing,Minnesota',
  '44.0121,-92.4802,Rochester,Minnesota',
  '45.5579,-94.1632,Saint Cloud,Minnesota',
  '44.9537,-93.0900,Saint Paul,Minnesota',
  '45.7375,-94.9525,Sauk Centre,Minnesota',
  '44.8760,-93.0276,South Saint Paul,Minnesota',
  '45.0560,-92.8088,Stillwater,Minnesota',
  '47.5233,-92.5366,Virginia,Minnesota',
  '45.1224,-95.0487,Willmar,Minnesota',
  '44.0554,-91.6664,Winona,Minnesota',
  '30.3088,-89.3300,Bay Saint Louis,Mississippi',
  '30.3960,-88.8853,Biloxi,Mississippi',
  '32.6126,-90.0368,Canton,Mississippi',
  '34.2001,-90.5709,Clarksdale,Mississippi',
  '31.2525,-89.8354,Columbia,Mississippi',
  '33.4957,-88.4273,Columbus,Mississippi',
  '34.9343,-88.5223,Corinth,Mississippi',
  '33.3997,-91.0377,Greenville,Mississippi',
  '33.5162,-90.1795,Greenwood,Mississippi',
  '33.7690,-89.8084,Grenada,Mississippi',
  '30.3674,-89.0928,Gulfport,Mississippi',
  '31.3271,-89.2903,Hattiesburg,Mississippi',
  '34.7676,-89.4487,Holly Springs,Mississippi',
  '32.2988,-90.1848,Jackson,Mississippi',
  '31.6941,-89.1306,Laurel,Mississippi',
  '32.3643,-88.7037,Meridian,Mississippi',
  '31.5604,-91.4032,Natchez,Mississippi',
  '30.4113,-88.8278,Ocean Springs,Mississippi',
  '34.3660,-89.5188,Oxford,Mississippi',
  '30.3658,-88.5561,Pascagoula,Mississippi',
  '30.3158,-89.2475,Pass Christian,Mississippi',
  '32.7715,-89.1167,Philadelphia,Mississippi',
  '31.9608,-90.9817,Port Gibson,Mississippi',
  '33.4504,-88.8184,Starkville,Mississippi',
  '34.2576,-88.7034,Tupelo,Mississippi',
  '32.3526,-90.8779,Vicksburg,Mississippi',
  '33.6076,-88.6503,West Point,Mississippi',
  '32.8551,-90.4056,Yazoo City,Mississippi',
  '38.9736,-92.7432,Boonville,Missouri',
  '36.6437,-93.2185,Branson,Missouri',
  '37.3059,-89.5181,Cape Girardeau,Missouri',
  '37.1764,-94.3102,Carthage,Missouri',
  '39.7953,-93.5524,Chillicothe,Missouri',
  '38.6426,-90.3237,Clayton,Missouri',
  '38.9517,-92.3341,Columbia,Missouri',
  '39.3392,-94.2261,Excelsior Springs,Missouri',
  '38.7442,-90.3054,Ferguson,Missouri',
  '38.7892,-90.3226,Florissant,Missouri',
  '38.8467,-91.9480,Fulton,Missouri',
  '39.7084,-91.3585,Hannibal,Missouri',
  '39.0911,-94.4155,Independence,Missouri',
  '38.5767,-92.1735,Jefferson City,Missouri',
  '37.0842,-94.5133,Joplin,Missouri',
  '39.0997,-94.5786,Kansas City,Missouri',
  '40.1948,-92.5832,Kirksville,Missouri',
  '37.4950,-94.2766,Lamar,Missouri',
  '37.6806,-92.6638,Lebanon,Missouri',
  '39.1847,-93.8799,Lexington,Missouri',
  '40.3461,-94.8725,Maryville,Missouri',
  '39.1731,-91.8873,Mexico,Missouri',
  '36.9198,-93.9276,Monett,Missouri',
  '36.8690,-94.3680,Neosho,Missouri',
  '36.5864,-89.5279,New Madrid,Missouri',
  '37.9485,-91.7715,Rolla,Missouri',
  '38.7881,-90.4974,Saint Charles,Missouri',
  '39.7675,-94.8467,Saint Joseph,Missouri',
  '38.6270,-90.1994,Saint Louis,Missouri',
  '37.9787,-90.0476,Sainte Genevieve,Missouri',
  '37.6456,-91.5360,Salem,Missouri',
  '38.7045,-93.2283,Sedalia,Missouri',
  '37.2090,-93.2923,Springfield,Missouri',
  '38.7628,-93.7360,Warrensburg,Missouri',
  '36.7281,-91.8524,West Plains,Missouri',
  '46.1263,-112.9478,Anaconda,Montana',
  '45.7833,-108.5007,Billings,Montana',
  '45.6793,-111.0373,Bozeman,Montana',
  '46.0038,-112.5348,Butte,Montana',
  '45.2166,-112.6389,Dillon,Montana',
  '47.8344,-110.6583,Fort Benton,Montana',
  '47.1064,-104.7108,Glendive,Montana',
  '47.5053,-111.3008,Great Falls,Montana',
  '48.5500,-109.6841,Havre,Montana',
  '46.5891,-112.0391,Helena,Montana',
  '48.1920,-114.3168,Kalispell,Montana',
  '47.0625,-109.4282,Lewistown,Montana',
  '45.6614,-110.5600,Livingston,Montana',
  '46.4083,-105.8406,Miles City,Montana',
  '46.8721,-113.9940,Missoula,Montana',
  '45.2938,-111.9461,Virginia City,Montana',
  '40.2659,-96.7467,Beatrice,Nebraska',
  '41.1515,-95.9177,Bellevue,Nebraska',
  '41.2611,-96.1320,Boys Town,Nebraska',
  '42.8294,-103.0006,Chadron,Nebraska',
  '41.4303,-97.3594,Columbus,Nebraska',
  '41.4334,-96.4981,Fremont,Nebraska',
  '40.9264,-98.3420,Grand Island,Nebraska',
  '40.5863,-98.3899,Hastings,Nebraska',
  '40.6993,-99.0817,Kearney,Nebraska',
  '40.8136,-96.7026,Lincoln,Nebraska',
  '40.1967,-100.6249,McCook,Nebraska',
  '40.4986,-98.9479,Minden,Nebraska',
  '40.6766,-95.8594,Nebraska City,Nebraska',
  '42.0327,-97.4168,Norfolk,Nebraska',
  '41.1403,-100.7601,North Platte,Nebraska',
  '41.2565,-95.9345,Omaha,Nebraska',
  '41.0111,-95.8808,Plattsmouth,Nebraska',
  '40.0890,-98.5196,Red Cloud,Nebraska',
  '41.1448,-102.9774,Sidney,Nebraska',
  '35.9782,-114.8345,Boulder City,Nevada',
  '39.1638,-119.7674,Carson City,Nevada',
  '40.8324,-115.7631,Elko,Nevada',
  '39.2533,-114.8742,Ely,Nevada',
  '39.4749,-118.7770,Fallon,Nevada',
  '39.0041,-119.8472,Genoa,Nevada',
  '37.7084,-117.2357,Goldfield,Nevada',
  '36.0395,-114.9817,Henderson,Nevada',
  '36.1716,-115.1391,Las Vegas,Nevada',
  '36.1989,-115.1175,North Las Vegas,Nevada',
  '39.5299,-119.8143,Reno,Nevada',
  '39.5349,-119.7527,Sparks,Nevada',
  '39.3095,-119.6500,Virginia City,Nevada',
  '40.9730,-117.7357,Winnemucca,Nevada',
  '44.4687,-71.1851,Berlin,New Hampshire',
  '43.3729,-72.3379,Claremont,New Hampshire',
  '43.2081,-71.5376,Concord,New Hampshire',
  '42.8806,-71.3273,Derry,New Hampshire',
  '43.1979,-70.8737,Dover,New Hampshire',
  '43.1340,-70.9264,Durham,New Hampshire',
  '42.9814,-70.9478,Exeter,New Hampshire',
  '43.4442,-71.6473,Franklin,New Hampshire',
  '43.7022,-72.2896,Hanover,New Hampshire',
  '43.1147,-71.8950,Hillsborough,New Hampshire',
  '42.9337,-72.2781,Keene,New Hampshire',
  '43.5279,-71.4704,Laconia,New Hampshire',
  '43.6423,-72.2518,Lebanon,New Hampshire',
  '42.9956,-71.4548,Manchester,New Hampshire',
  '42.7654,-71.4676,Nashua,New Hampshire',
  '42.8706,-71.9517,Peterborough,New Hampshire',
  '43.7570,-71.6882,Plymouth,New Hampshire',
  '43.0718,-70.7626,Portsmouth,New Hampshire',
  '43.3045,-70.9756,Rochester,New Hampshire',
  '42.7886,-71.2009,Salem,New Hampshire',
  '43.2618,-70.8653,Somersworth,New Hampshire',
  '40.2204,-74.0121,Asbury Park,New Jersey',
  '39.3643,-74.4229,Atlantic City,New Jersey',
  '40.6687,-74.1143,Bayonne,New Jersey',
  '40.8068,-74.1854,Bloomfield,New Jersey',
  '40.1462,-74.7118,Bordentown,New Jersey',
  '40.5684,-74.5385,Bound Brook,New Jersey',
  '39.4273,-75.2341,Bridgeton,New Jersey',
  '40.0712,-74.8649,Burlington,New Jersey',
  '40.8398,-74.2765,Caldwell,New Jersey',
  '39.9259,-75.1196,Camden,New Jersey',
  '38.9351,-74.9060,Cape May,New Jersey',
  '40.8584,-74.1638,Clifton,New Jersey',
  '40.6574,-74.3050,Cranford,New Jersey',
  '40.7673,-74.2049,East Orange,New Jersey',
  '40.5187,-74.4121,Edison,New Jersey',
  '40.6640,-74.2107,Elizabeth,New Jersey',
  '40.8929,-73.9726,Englewood,New Jersey',
  '40.8509,-73.9701,Fort Lee,New Jersey',
  '39.7029,-75.1118,Glassboro,New Jersey',
  '40.8859,-74.0435,Hackensack,New Jersey',
  '39.8915,-75.0377,Haddonfield,New Jersey',
  '40.7440,-74.0324,Hoboken,New Jersey',
  '40.7263,-74.2286,Irvington,New Jersey',
  '40.7178,-74.0431,Jersey City,New Jersey',
  '40.0146,-74.3113,Lakehurst,New Jersey',
  '40.0821,-74.2097,Lakewood,New Jersey',
  '39.6155,-74.1995,Long Beach,New Jersey',
  '40.3043,-73.9924,Long Branch,New Jersey',
  '40.7598,-74.4171,Madison,New Jersey',
  '40.5187,-74.4121,Menlo Park,New Jersey',
  '40.7239,-74.3071,Millburn,New Jersey',
  '39.4021,-75.0393,Millville,New Jersey',
  '40.8259,-74.2090,Montclair,New Jersey',
  '40.7968,-74.4815,Morristown,New Jersey',
  '39.9931,-74.7879,Mount Holly,New Jersey',
  '40.4862,-74.4518,New Brunswick,New Jersey',
  '40.9351,-74.0190,New Milford,New Jersey',
  '40.7357,-74.1724,Newark,New Jersey',
  '39.2776,-74.5746,Ocean City,New Jersey',
  '40.7706,-74.2326,Orange,New Jersey',
  '40.8529,-74.3921,Parsippany–Troy Hills,New Jersey',
  '40.8568,-74.1285,Passaic,New Jersey',
  '40.9168,-74.1718,Paterson,New Jersey',
  '40.5068,-74.2654,Perth Amboy,New Jersey',
  '40.6337,-74.4074,Plainfield,New Jersey',
  '40.3573,-74.6672,Princeton,New Jersey',
  '40.9793,-74.1165,Ridgewood,New Jersey',
  '40.6527,-74.2593,Roselle,New Jersey',
  '40.8265,-74.1068,Rutherford,New Jersey',
  '39.5718,-75.4671,Salem,New Jersey',
  '40.5743,-74.6099,Somerville,New Jersey',
  '40.7489,-74.2610,South Orange Village,New Jersey',
  '40.9051,-74.2099,Totowa,New Jersey',
  '40.2206,-74.7597,Trenton,New Jersey',
  '40.6976,-74.2632,Union,New Jersey',
  '40.7795,-74.0238,Union City,New Jersey',
  '39.4864,-75.0260,Vineland,New Jersey',
  '40.9254,-74.2765,Wayne,New Jersey',
  '40.7664,-74.0254,Weehawken,New Jersey',
  '40.7879,-74.0143,West New York,New Jersey',
  '40.7986,-74.2391,West Orange,New Jersey',
  '40.0288,-74.8916,Willingboro,New Jersey',
  '40.5576,-74.2846,Woodbridge,New Jersey',
  '34.8963,-107.5823,Acoma,New Mexico',
  '32.8995,-105.9603,Alamogordo,New Mexico',
  '35.0844,-106.6504,Albuquerque,New Mexico',
  '32.8423,-104.4033,Artesia,New Mexico',
  '34.6628,-106.7762,Belen,New Mexico',
  '32.4203,-104.2290,Carlsbad,New Mexico',
  '34.4048,-103.2052,Clovis,New Mexico',
  '32.2687,-107.7586,Deming,New Mexico',
  '36.7281,-108.2187,Farmington,New Mexico',
  '35.5281,-108.7426,Gallup,New Mexico',
  '35.1473,-107.8514,Grants,New Mexico',
  '32.7026,-103.1360,Hobbs,New Mexico',
  '32.3199,-106.7637,Las Cruces,New Mexico',
  '35.5942,-105.2228,Las Vegas,New Mexico',
  '35.8800,-106.3031,Los Alamos,New Mexico',
  '32.9440,-103.3486,Lovington,New Mexico',
  '34.1862,-103.3344,Portales,New Mexico',
  '36.9034,-104.4392,Raton,New Mexico',
  '33.3943,-104.5230,Roswell,New Mexico',
  '35.6870,-105.9378,Santa Fe,New Mexico',
  '36.7856,-108.6870,Shiprock,New Mexico',
  '32.7701,-108.2803,Silver City,New Mexico',
  '34.0584,-106.8914,Socorro,New Mexico',
  '36.4072,-105.5734,Taos,New Mexico',
  '33.1284,-107.2528,Truth or Consequences,New Mexico',
  '35.1717,-103.7250,Tucumcari,New Mexico',
  '42.6526,-73.7562,Albany,New York',
  '42.9377,-74.1904,Amsterdam,New York',
  '42.9317,-76.5661,Auburn,New York',
  '40.7107,-73.3673,Babylon,New York',
  '42.9981,-78.1875,Batavia,New York',
  '41.5048,-73.9696,Beacon,New York',
  '41.2043,-73.6437,Bedford,New York',
  '42.0987,-75.9180,Binghamton,New York',
  '40.8448,-73.8648,Bronx,New York',
  '40.6782,-73.9442,Brooklyn,New York',
  '42.8864,-78.8784,Buffalo,New York',
  '42.2098,-79.4668,Chautauqua,New York',
  '42.9071,-78.7543,Cheektowaga,New York',
  '43.0484,-75.3785,Clinton,New York',
  '42.7742,-73.7001,Cohoes,New York',
  '40.5755,-73.9707,Coney Island,New York',
  '42.7006,-74.9243,Cooperstown,New York',
  '42.1429,-77.0547,Corning,New York',
  '42.6012,-76.1805,Cortland,New York',
  '43.9503,-73.4371,Crown Point,New York',
  '42.4795,-79.3339,Dunkirk,New York',
  '42.7678,-78.6134,East Aurora,New York',
  '40.9634,-72.1848,East Hampton,New York',
  '40.9596,-73.8096,Eastchester,New York',
  '42.0898,-76.8077,Elmira,New York',
  '40.7647,-73.8307,Flushing,New York',
  '40.7181,-73.8448,Forest Hills,New York',
  '42.4401,-79.3317,Fredonia,New York',
  '40.7268,-73.6343,Garden City,New York',
  '42.8680,-76.9856,Geneva,New York',
  '43.3095,-73.6440,Glens Falls,New York',
  '43.0529,-74.3437,Gloversville,New York',
  '40.8007,-73.7285,Great Neck,New York',
  '42.4078,-77.2236,Hammondsport,New York',
  '40.8116,-73.9465,Harlem,New York',
  '40.7062,-73.6187,Hempstead,New York',
  '43.0256,-74.9860,Herkimer,New York',
  '42.2529,-73.7910,Hudson,New York',
  '40.8682,-73.4257,Huntington,New York',
  '41.7847,-73.9333,Hyde Park,New York',
  '43.0151,-75.0354,Ilion,New York',
  '42.4440,-76.5019,Ithaca,New York',
  '42.0970,-79.2353,Jamestown,New York',
  '43.0067,-74.3676,Johnstown,New York',
  '41.9270,-73.9974,Kingston,New York',
  '42.8256,-78.8234,Lackawanna,New York',
  '44.2795,-73.9799,Lake Placid,New York',
  '40.7259,-73.5143,Levittown,New York',
  '43.1706,-78.6903,Lockport,New York',
  '40.9487,-73.7326,Mamaroneck,New York',
  '40.7831,-73.9712,Manhattan,New York',
  '44.9281,-74.8919,Massena,New York',
  '41.4459,-74.4229,Middletown,New York',
  '40.7493,-73.6407,Mineola,New York',
  '40.9126,-73.8371,Mount Vernon,New York',
  '41.7476,-74.0868,New Paltz,New York',
  '40.9115,-73.7824,New Rochelle,New York',
  '41.4768,-74.0238,New Windsor,New York',
  '40.7128,-74.0060,New York City,New York',
  '41.5034,-74.0104,Newburgh,New York',
  '43.0962,-79.0377,Niagara Falls,New York',
  '40.8099,-73.6772,North Hempstead,New York',
  '41.0907,-73.9179,Nyack,New York',
  '44.6942,-75.4863,Ogdensburg,New York',
  '42.0836,-78.4299,Olean,New York',
  '43.0926,-75.6513,Oneida,New York',
  '42.4529,-75.0638,Oneonta,New York',
  '41.1629,-73.8615,Ossining,New York',
  '43.4553,-76.5105,Oswego,New York',
  '40.8657,-73.5321,Oyster Bay,New York',
  '43.0640,-77.2333,Palmyra,New York',
  '41.2901,-73.9204,Peekskill,New York',
  '44.6995,-73.4529,Plattsburgh,New York',
  '40.8257,-73.6982,Port Washington,New York',
  '44.6698,-74.9813,Potsdam,New York',
  '41.7004,-73.9210,Poughkeepsie,New York',
  '40.7282,-73.7949,Queens,New York',
  '42.6426,-73.7429,Rensselaer,New York',
  '43.1566,-77.6088,Rochester,New York',
  '43.2128,-75.4557,Rome,New York',
  '42.7870,-73.9710,Rotterdam,New York',
  '40.9807,-73.6837,Rye,New York',
  '40.9979,-72.2926,Sag Harbor,New York',
  '44.3295,-74.1313,Saranac Lake,New York',
  '43.0831,-73.7846,Saratoga Springs,New York',
  '41.0051,-73.7846,Scarsdale,New York',
  '42.8142,-73.9396,Schenectady,New York',
  '42.9106,-76.7966,Seneca Falls,New York',
  '40.8599,-72.5678,Southampton,New York',
  '40.5795,-74.1502,Staten Island,New York',
  '40.9257,-73.1409,Stony Brook,New York',
  '41.2295,-73.9871,Stony Point,New York',
  '43.0481,-76.1474,Syracuse,New York',
  '41.0762,-73.8587,Tarrytown,New York',
  '43.8487,-73.4235,Ticonderoga,New York',
  '43.0203,-78.8803,Tonawanda,New York',
  '42.7284,-73.6918,Troy,New York',
  '43.1009,-75.2327,Utica,New York',
  '43.9748,-75.9108,Watertown,New York',
  '42.7301,-73.7012,Watervliet,New York',
  '42.3806,-76.8733,Watkins Glen,New York',
  '42.8359,-78.7539,West Seneca,New York',
  '41.0340,-73.7629,White Plains,New York',
  '42.0409,-74.1182,Woodstock,New York',
  '40.9312,-73.8987,Yonkers,New York',
  '35.7079,-79.8136,Asheboro,North Carolina',
  '35.5951,-82.5515,Asheville,North Carolina',
  '35.4771,-76.8116,Bath,North Carolina',
  '34.7182,-76.6638,Beaufort,North Carolina',
  '36.2168,-81.6746,Boone,North Carolina',
  '36.0959,-79.4373,Burlington,North Carolina',
  '35.9132,-79.0558,Chapel Hill,North Carolina',
  '35.2271,-80.8431,Charlotte,North Carolina',
  '35.4088,-80.5795,Concord,North Carolina',
  '35.9940,-78.8986,Durham,North Carolina',
  '36.0579,-76.6077,Edenton,North Carolina',
  '36.2946,-76.2510,Elizabeth City,North Carolina',
  '35.0524,-78.8784,Fayetteville,North Carolina',
  '35.2622,-81.1871,Gastonia,North Carolina',
  '35.3849,-77.9928,Goldsboro,North Carolina',
  '36.0726,-79.7920,Greensboro,North Carolina',
  '35.6127,-77.3664,Greenville,North Carolina',
  '36.3285,-77.5894,Halifax,North Carolina',
  '36.3273,-78.4027,Henderson,North Carolina',
  '35.7345,-81.3445,Hickory,North Carolina',
  '35.9557,-80.0053,High Point,North Carolina',
  '36.0754,-79.0997,Hillsborough,North Carolina',
  '34.7541,-77.4302,Jacksonville,North Carolina',
  '35.2627,-77.5816,Kinston,North Carolina',
  '36.0646,-75.7057,Kitty Hawk,North Carolina',
  '34.6182,-79.0086,Lumberton,North Carolina',
  '34.7229,-76.7260,Morehead City,North Carolina',
  '35.7454,-81.6848,Morganton,North Carolina',
  '35.9574,-75.6241,Nags Head,North Carolina',
  '35.1085,-77.0441,New Bern,North Carolina',
  '35.1954,-79.4695,Pinehurst,North Carolina',
  '35.7796,-78.6382,Raleigh,North Carolina',
  '35.9382,-77.7905,Rocky Mount,North Carolina',
  '35.6710,-80.4742,Salisbury,North Carolina',
  '35.2924,-81.5356,Shelby,North Carolina',
  '35.5466,-77.0522,Washington,North Carolina',
  '34.2104,-77.8868,Wilmington,North Carolina',
  '35.7258,-77.9111,Wilson,North Carolina',
  '36.0999,-80.2442,Winston-Salem,North Carolina',
  '46.8055,-100.7877,Bismarck,North Dakota',
  '48.1128,-98.8651,Devils Lake,North Dakota',
  '46.8792,-102.7896,Dickinson,North Dakota',
  '46.8772,-96.7898,Fargo,North Dakota',
  '47.9253,-97.0329,Grand Forks,North Dakota',
  '46.9105,-98.7084,Jamestown,North Dakota',
  '46.8267,-100.8896,Mandan,North Dakota',
  '48.2330,-101.2923,Minot,North Dakota',
  '48.3689,-99.9962,Rugby,North Dakota',
  '46.9233,-98.0032,Valley City,North Dakota',
  '46.2652,-96.6059,Wahpeton,North Dakota',
  '48.1470,-103.6180,Williston,North Dakota',
  '41.0814,-81.5190,Akron,Ohio',
  '40.9153,-81.1059,Alliance,Ohio',
  '41.8651,-80.7898,Ashtabula,Ohio',
  '39.3292,-82.1013,Athens,Ohio',
  '41.0128,-81.6051,Barberton,Ohio',
  '41.3931,-81.5365,Bedford,Ohio',
  '40.3612,-83.7597,Bellefontaine,Ohio',
  '41.3748,-83.6513,Bowling Green,Ohio',
  '40.7989,-81.3784,Canton,Ohio',
  '39.3331,-82.9824,Chillicothe,Ohio',
  '39.1031,-84.5120,Cincinnati,Ohio',
  '41.4993,-81.6944,Cleveland,Ohio',
  '41.5201,-81.5562,Cleveland Heights,Ohio',
  '39.9612,-82.9988,Columbus,Ohio',
  '41.9476,-80.5542,Conneaut,Ohio',
  '41.1339,-81.4846,Cuyahoga Falls,Ohio',
  '39.7589,-84.1916,Dayton,Ohio',
  '41.2845,-84.3558,Defiance,Ohio',
  '40.2987,-83.0680,Delaware,Ohio',
  '41.5331,-81.5790,East Cleveland,Ohio',
  '40.6187,-80.5773,East Liverpool,Ohio',
  '41.3684,-82.1076,Elyria,Ohio',
  '41.5931,-81.5268,Euclid,Ohio',
  '41.0442,-83.6499,Findlay,Ohio',
  '38.8098,-82.2024,Gallipolis,Ohio',
  '40.1028,-84.6330,Greenville,Ohio',
  '39.3995,-84.5613,Hamilton,Ohio',
  '41.1537,-81.3579,Kent,Ohio',
  '39.6895,-84.1688,Kettering,Ohio',
  '41.4820,-81.7982,Lakewood,Ohio',
  '39.7137,-82.5993,Lancaster,Ohio',
  '40.7426,-84.1052,Lima,Ohio',
  '41.4528,-82.1824,Lorain,Ohio',
  '40.7584,-82.5154,Mansfield,Ohio',
  '39.4154,-81.4548,Marietta,Ohio',
  '40.5887,-83.1285,Marion,Ohio',
  '40.0959,-80.7245,Martins Ferry,Ohio',
  '40.7967,-81.5215,Massillon,Ohio',
  '41.6662,-81.3396,Mentor,Ohio',
  '39.5151,-84.3983,Middletown,Ohio',
  '41.2976,-82.6055,Milan,Ohio',
  '40.3932,-82.4854,Mount Vernon,Ohio',
  '40.4898,-81.4457,New Philadelphia,Ohio',
  '40.0581,-82.4013,Newark,Ohio',
  '41.1828,-80.7654,Niles,Ohio',
  '39.2184,-84.5508,North College Hill,Ohio',
  '41.2426,-82.6157,Norwalk,Ohio',
  '41.2939,-82.2174,Oberlin,Ohio',
  '41.7245,-81.2457,Painesville,Ohio',
  '41.4048,-81.7229,Parma,Ohio',
  '40.1448,-84.2424,Piqua,Ohio',
  '38.7317,-82.9977,Portsmouth,Ohio',
  '41.6521,-82.8200,Put-in-Bay,Ohio',
  '40.9009,-80.8568,Salem,Ohio',
  '41.4562,-82.7117,Sandusky,Ohio',
  '41.4739,-81.5371,Shaker Heights,Ohio',
  '39.9242,-83.8088,Springfield,Ohio',
  '40.3698,-80.6340,Steubenville,Ohio',
  '41.1145,-83.1780,Tiffin,Ohio',
  '41.6528,-83.5379,Toledo,Ohio',
  '40.1084,-83.7524,Urbana,Ohio',
  '41.2376,-80.8184,Warren,Ohio',
  '40.7982,-81.9399,Wooster,Ohio',
  '40.0931,-83.0180,Worthington,Ohio',
  '39.6848,-83.9297,Xenia,Ohio',
  '39.8064,-83.8869,Yellow Springs,Ohio',
  '41.0998,-80.6495,Youngstown,Ohio',
  '39.9403,-82.0132,Zanesville,Ohio',
  '34.7745,-96.6783,Ada,Oklahoma',
  '34.6378,-99.3340,Altus,Oklahoma',
  '36.8050,-98.6665,Alva,Oklahoma',
  '35.0726,-98.2437,Anadarko,Oklahoma',
  '34.1743,-97.1436,Ardmore,Oklahoma',
  '36.7473,-95.9808,Bartlesville,Oklahoma',
  '35.5187,-97.6323,Bethany,Oklahoma',
  '35.0526,-97.9364,Chickasha,Oklahoma',
  '36.3126,-95.6161,Claremore,Oklahoma',
  '35.5156,-98.9673,Clinton,Oklahoma',
  '35.9851,-96.7670,Cushing,Oklahoma',
  '34.5023,-97.9578,Duncan,Oklahoma',
  '33.9924,-96.3971,Durant,Oklahoma',
  '35.6528,-97.4781,Edmond,Oklahoma',
  '35.5323,-97.9550,El Reno,Oklahoma',
  '35.4120,-99.4043,Elk City,Oklahoma',
  '36.3956,-97.8784,Enid,Oklahoma',
  '35.2873,-95.5825,Eufaula,Oklahoma',
  '34.3920,-99.0184,Frederick,Oklahoma',
  '35.8789,-97.4253,Guthrie,Oklahoma',
  '36.6828,-101.4815,Guymon,Oklahoma',
  '35.0295,-99.0931,Hobart,Oklahoma',
  '35.0804,-96.3992,Holdenville,Oklahoma',
  '34.0107,-95.5097,Hugo,Oklahoma',
  '34.6036,-98.3959,Lawton,Oklahoma',
  '34.9334,-95.7697,McAlester,Oklahoma',
  '36.8745,-94.8775,Miami,Oklahoma',
  '35.4495,-97.3967,Midwest City,Oklahoma',
  '35.3395,-97.4867,Moore,Oklahoma',
  '35.7479,-95.3697,Muskogee,Oklahoma',
  '35.2226,-97.4395,Norman,Oklahoma',
  '35.4676,-97.5164,Oklahoma City,Oklahoma',
  '35.6267,-95.9515,Okmulgee,Oklahoma',
  '34.7401,-97.2222,Pauls Valley,Oklahoma',
  '36.6678,-96.3372,Pawhuska,Oklahoma',
  '36.2895,-97.2881,Perry,Oklahoma',
  '36.7070,-97.0856,Ponca City,Oklahoma',
  '36.3084,-95.3169,Pryor,Oklahoma',
  '35.4604,-94.7874,Sallisaw,Oklahoma',
  '36.1398,-96.1089,Sand Springs,Oklahoma',
  '35.9987,-96.1142,Sapulpa,Oklahoma',
  '35.2245,-96.6706,Seminole,Oklahoma',
  '35.3273,-96.9253,Shawnee,Oklahoma',
  '36.1156,-97.0584,Stillwater,Oklahoma',
  '35.9154,-94.9700,Tahlequah,Oklahoma',
  '35.5609,-97.5514,The Village,Oklahoma',
  '36.1540,-95.9928,Tulsa,Oklahoma',
  '36.6387,-95.1541,Vinita,Oklahoma',
  '35.1587,-96.4933,Wewoka,Oklahoma',
  '36.4336,-99.3904,Woodward,Oklahoma',
  '44.6365,-123.1059,Albany,Oregon',
  '42.1946,-122.7095,Ashland,Oregon',
  '46.1879,-123.8313,Astoria,Oregon',
  '44.7749,-117.8344,Baker City,Oregon',
  '45.4869,-122.8040,Beaverton,Oregon',
  '44.0582,-121.3153,Bend,Oregon',
  '42.0526,-124.2840,Brookings,Oregon',
  '43.5863,-119.0541,Burns,Oregon',
  '43.3672,-124.2131,Coos Bay,Oregon',
  '44.5646,-123.2620,Corvallis,Oregon',
  '44.0521,-123.0868,Eugene,Oregon',
  '42.4390,-123.3284,Grants Pass,Oregon',
  '45.5201,-122.9898,Hillsboro,Oregon',
  '45.7068,-121.5281,Hood River,Oregon',
  '42.3135,-122.9670,Jacksonville,Oregon',
  '44.4160,-118.9530,John Day,Oregon',
  '42.2249,-121.7817,Klamath Falls,Oregon',
  '45.3246,-118.0877,La Grande,Oregon',
  '45.4193,-122.6630,Lake Oswego,Oregon',
  '42.1888,-120.3458,Lakeview,Oregon',
  '45.2108,-123.1945,McMinnville,Oregon',
  '42.3265,-122.8756,Medford,Oregon',
  '45.3003,-122.9761,Newberg,Oregon',
  '44.6368,-124.0535,Newport,Oregon',
  '44.0266,-116.9629,Ontario,Oregon',
  '45.3556,-122.6059,Oregon City,Oregon',
  '45.6721,-118.7886,Pendleton,Oregon',
  '42.7457,-124.4973,Port Orford,Oregon',
  '45.5152,-122.6784,Portland,Oregon',
  '44.2998,-120.8345,Prineville,Oregon',
  '44.2726,-121.1739,Redmond,Oregon',
  '43.7023,-124.0968,Reedsport,Oregon',
  '43.2165,-123.3417,Roseburg,Oregon',
  '44.9429,-123.0351,Salem,Oregon',
  '45.9932,-123.9226,Seaside,Oregon',
  '44.0462,-123.0220,Springfield,Oregon',
  '45.6018,-121.1848,The Dalles,Oregon',
  '45.4562,-123.8440,Tillamook,Oregon',
  '40.1241,-75.1195,Abington,Pennsylvania',
  '40.6155,-80.2622,Aliquippa,Pennsylvania',
  '40.6023,-75.4714,Allentown,Pennsylvania',
  '40.5187,-78.3947,Altoona,Pennsylvania',
  '40.5892,-80.2251,Ambridge,Pennsylvania',
  '40.0187,-78.5039,Bedford,Pennsylvania',
  '40.6259,-75.3705,Bethlehem,Pennsylvania',
  '41.0037,-76.4549,Bloomsburg,Pennsylvania',
  '41.9565,-78.6492,Bradford,Pennsylvania',
  '40.1007,-74.8518,Bristol,Pennsylvania',
  '41.5737,-75.5019,Carbondale,Pennsylvania',
  '40.2010,-77.2003,Carlisle,Pennsylvania',
  '39.9376,-77.6611,Chambersburg,Pennsylvania',
  '39.8496,-75.3557,Chester,Pennsylvania',
  '40.0337,-76.5044,Columbia,Pennsylvania',
  '40.6884,-75.2207,Easton,Pennsylvania',
  '42.1292,-80.0851,Erie,Pennsylvania',
  '41.3978,-79.8314,Franklin,Pennsylvania',
  '40.0293,-75.1746,Germantown,Pennsylvania',
  '39.8309,-77.2311,Gettysburg,Pennsylvania',
  '40.3015,-79.5389,Greensburg,Pennsylvania',
  '39.8007,-76.9830,Hanover,Pennsylvania',
  '40.8015,-80.1273,Harmony,Pennsylvania',
  '40.2732,-76.8867,Harrisburg,Pennsylvania',
  '40.9584,-75.9746,Hazleton,Pennsylvania',
  '40.2859,-76.6502,Hershey,Pennsylvania',
  '40.4059,-79.9120,Homestead,Pennsylvania',
  '41.5768,-75.2588,Honesdale,Pennsylvania',
  '40.6215,-79.1525,Indiana,Pennsylvania',
  '40.3281,-79.6153,Jeannette,Pennsylvania',
  '40.8759,-75.7324,Jim Thorpe,Pennsylvania',
  '40.3267,-78.9220,Johnstown,Pennsylvania',
  '40.0379,-76.3055,Lancaster,Pennsylvania',
  '40.3409,-76.4113,Lebanon,Pennsylvania',
  '40.1551,-74.8288,Levittown,Pennsylvania',
  '40.5992,-77.5714,Lewistown,Pennsylvania',
  '41.1370,-77.4469,Lock Haven,Pennsylvania',
  '40.1488,-75.0027,Lower Southampton,Pennsylvania',
  '40.3471,-79.8641,McKeesport,Pennsylvania',
  '41.6414,-80.1514,Meadville,Pennsylvania',
  '40.1998,-76.7311,Middletown,Pennsylvania',
  '40.4212,-79.7881,Monroeville,Pennsylvania',
  '41.2054,-76.0049,Nanticoke,Pennsylvania',
  '41.0037,-80.3470,New Castle,Pennsylvania',
  '40.3643,-74.9513,New Hope,Pennsylvania',
  '40.5698,-79.7648,New Kensington,Pennsylvania',
  '40.1215,-75.3399,Norristown,Pennsylvania',
  '41.4339,-79.7064,Oil City,Pennsylvania',
  '39.9526,-75.1652,Philadelphia,Pennsylvania',
  '40.1304,-75.5149,Phoenixville,Pennsylvania',
  '40.4406,-79.9959,Pittsburgh,Pennsylvania',
  '40.2454,-75.6496,Pottstown,Pennsylvania',
  '40.6856,-76.1955,Pottsville,Pennsylvania',
  '40.3356,-75.9269,Reading,Pennsylvania',
  '41.4090,-75.6624,Scranton,Pennsylvania',
  '40.7890,-76.5588,Shamokin,Pennsylvania',
  '41.2331,-80.4934,Sharon,Pennsylvania',
  '40.7934,-77.8600,State College,Pennsylvania',
  '40.9868,-75.1946,Stroudsburg,Pennsylvania',
  '40.8626,-76.7944,Sunbury,Pennsylvania',
  '39.9021,-75.3499,Swarthmore,Pennsylvania',
  '40.7973,-75.9694,Tamaqua,Pennsylvania',
  '41.6270,-79.6737,Titusville,Pennsylvania',
  '39.9001,-79.7164,Uniontown,Pennsylvania',
  '41.8439,-79.1450,Warren,Pennsylvania',
  '40.1740,-80.2462,Washington,Pennsylvania',
  '39.9607,-75.6055,West Chester,Pennsylvania',
  '41.2459,-75.8813,Wilkes-Barre,Pennsylvania',
  '41.2412,-77.0011,Williamsport,Pennsylvania',
  '39.9626,-76.7277,York,Pennsylvania',
  '41.7407,-71.3086,Barrington,Rhode Island',
  '41.6771,-71.2662,Bristol,Rhode Island',
  '41.8907,-71.3923,Central Falls,Rhode Island',
  '41.7798,-71.4373,Cranston,Rhode Island',
  '41.6343,-71.5130,East Greenwich,Rhode Island',
  '41.8137,-71.3701,East Providence,Rhode Island',
  '41.4804,-71.5226,Kingston,Rhode Island',
  '41.5218,-71.2835,Middletown,Rhode Island',
  '41.4501,-71.4495,Narragansett,Rhode Island',
  '41.4901,-71.3128,Newport,Rhode Island',
  '41.5568,-71.4537,North Kingstown,Rhode Island',
  '41.8787,-71.3826,Pawtucket,Rhode Island',
  '41.6023,-71.2503,Portsmouth,Rhode Island',
  '41.8240,-71.4128,Providence,Rhode Island',
  '41.4476,-71.5247,South Kingstown,Rhode Island',
  '41.6259,-71.2134,Tiverton,Rhode Island',
  '41.7304,-71.2825,Warren,Rhode Island',
  '41.7001,-71.4162,Warwick,Rhode Island',
  '41.3776,-71.8273,Westerly,Rhode Island',
  '41.5723,-71.4485,Wickford,Rhode Island',
  '42.0029,-71.5148,Woonsocket,Rhode Island',
  '34.1782,-82.3790,Abbeville,South Carolina',
  '33.5604,-81.7196,Aiken,South Carolina',
  '34.5034,-82.6501,Anderson,South Carolina',
  '32.4316,-80.6698,Beaufort,South Carolina',
  '34.2465,-80.6070,Camden,South Carolina',
  '32.7765,-79.9311,Charleston,South Carolina',
  '34.0007,-81.0348,Columbia,South Carolina',
  '34.3004,-79.8757,Darlington,South Carolina',
  '34.1954,-79.7626,Florence,South Carolina',
  '35.0718,-81.6498,Gaffney,South Carolina',
  '33.3768,-79.2945,Georgetown,South Carolina',
  '34.8526,-82.3940,Greenville,South Carolina',
  '34.1954,-82.1618,Greenwood,South Carolina',
  '34.3743,-80.0733,Hartsville,South Carolina',
  '34.7204,-80.7709,Lancaster,South Carolina',
  '32.8323,-79.8284,Mount Pleasant,South Carolina',
  '33.6891,-78.8867,Myrtle Beach,South Carolina',
  '33.4918,-80.8556,Orangeburg,South Carolina',
  '34.9249,-81.0251,Rock Hill,South Carolina',
  '34.9496,-81.9320,Spartanburg,South Carolina',
  '33.9204,-80.3415,Sumter,South Carolina',
  '34.7154,-81.6237,Union,South Carolina',
  '45.4647,-98.4865,Aberdeen,South Dakota',
  '44.6714,-103.8521,Belle Fourche,South Dakota',
  '44.3114,-96.7984,Brookings,South Dakota',
  '43.3008,-96.5928,Canton,South Dakota',
  '43.7667,-103.5988,Custer,South Dakota',
  '44.3875,-97.5504,De Smet,South Dakota',
  '44.3767,-103.7296,Deadwood,South Dakota',
  '43.4316,-103.4744,Hot Springs,South Dakota',
  '44.3633,-98.2143,Huron,South Dakota',
  '44.3522,-103.7652,Lead,South Dakota',
  '44.0061,-97.1139,Madison,South Dakota',
  '45.2150,-96.6338,Milbank,South Dakota',
  '43.7094,-98.0298,Mitchell,South Dakota',
  '45.5372,-100.4279,Mobridge,South Dakota',
  '44.3668,-100.3538,Pierre,South Dakota',
  '44.0805,-103.2310,Rapid City,South Dakota',
  '43.5460,-96.7313,Sioux Falls,South Dakota',
  '44.4908,-103.8594,Spearfish,South Dakota',
  '44.4097,-103.5091,Sturgis,South Dakota',
  '42.7794,-96.9292,Vermillion,South Dakota',
  '44.8994,-97.1151,Watertown,South Dakota',
  '42.8711,-97.3973,Yankton,South Dakota',
  '35.7895,-83.9738,Alcoa,Tennessee',
  '35.4429,-84.5930,Athens,Tennessee',
  '35.0458,-85.3094,Chattanooga,Tennessee',
  '36.5298,-87.3595,Clarksville,Tennessee',
  '35.1595,-84.8766,Cleveland,Tennessee',
  '35.6151,-87.0353,Columbia,Tennessee',
  '36.1628,-85.5016,Cookeville,Tennessee',
  '35.4940,-85.0125,Dayton,Tennessee',
  '36.3487,-82.2107,Elizabethton,Tennessee',
  '35.9251,-86.8689,Franklin,Tennessee',
  '36.3884,-86.4467,Gallatin,Tennessee',
  '35.7143,-83.5102,Gatlinburg,Tennessee',
  '36.1632,-82.8310,Greeneville,Tennessee',
  '35.6145,-88.8139,Jackson,Tennessee',
  '36.3134,-82.3535,Johnson City,Tennessee',
  '36.2943,-82.4735,Jonesborough,Tennessee',
  '36.5484,-82.5618,Kingsport,Tennessee',
  '35.9606,-83.9207,Knoxville,Tennessee',
  '36.2081,-86.2911,Lebanon,Tennessee',
  '35.7565,-83.9705,Maryville,Tennessee',
  '35.1495,-90.0490,Memphis,Tennessee',
  '36.2140,-83.2949,Morristown,Tennessee',
  '35.8456,-86.3903,Murfreesboro,Tennessee',
  '36.1627,-86.7816,Nashville,Tennessee',
  '36.1956,-84.0680,Norris,Tennessee',
  '36.0104,-84.2696,Oak Ridge,Tennessee',
  '35.4834,-86.4603,Shelbyville,Tennessee',
  '35.3620,-86.2094,Tullahoma,Tennessee',
  '32.4487,-99.7331,Abilene,Texas',
  '30.3585,-103.6610,Alpine,Texas',
  '35.2220,-101.8313,Amarillo,Texas',
  '32.7357,-97.1081,Arlington,Texas',
  '30.2672,-97.7431,Austin,Texas',
  '29.7355,-94.9774,Baytown,Texas',
  '30.0802,-94.1266,Beaumont,Texas',
  '32.2504,-101.4787,Big Spring,Texas',
  '35.6678,-101.3974,Borger,Texas',
  '25.9017,-97.4975,Brownsville,Texas',
  '30.6744,-96.3700,Bryan,Texas',
  '34.9803,-101.9188,Canyon,Texas',
  '32.3476,-97.3867,Cleburne,Texas',
  '30.6280,-96.3344,College Station,Texas',
  '27.8006,-97.3964,Corpus Christi,Texas',
  '28.6775,-99.8281,Crystal City,Texas',
  '32.7767,-96.7970,Dallas,Texas',
  '29.3709,-100.8959,Del Rio,Texas',
  '33.7557,-96.5367,Denison,Texas',
  '33.2148,-97.1331,Denton,Texas',
  '28.7091,-100.4995,Eagle Pass,Texas',
  '26.3017,-98.1633,Edinburg,Texas',
  '31.7619,-106.4850,El Paso,Texas',
  '32.7555,-97.3308,Fort Worth,Texas',
  '28.9541,-95.3597,Freeport,Texas',
  '29.3013,-94.7977,Galveston,Texas',
  '32.9126,-96.6389,Garland,Texas',
  '28.6683,-97.3883,Goliad,Texas',
  '33.1398,-96.1072,Greenville,Texas',
  '26.1906,-97.6961,Harlingen,Texas',
  '29.7604,-95.3698,Houston,Texas',
  '30.7235,-95.5508,Huntsville,Texas',
  '32.8140,-96.9489,Irving,Texas',
  '30.2767,-98.4120,Johnson City,Texas',
  '32.3863,-94.8758,Kilgore,Texas',
  '31.1171,-97.7278,Killeen,Texas',
  '27.5159,-97.8561,Kingsville,Texas',
  '27.5036,-99.5076,Laredo,Texas',
  '32.5007,-94.7405,Longview,Texas',
  '33.5846,-101.8456,Lubbock,Texas',
  '31.3346,-94.7321,Lufkin,Texas',
  '32.5449,-94.3674,Marshall,Texas',
  '26.2034,-98.2300,McAllen,Texas',
  '33.1983,-96.6389,McKinney,Texas',
  '32.7668,-96.5992,Mesquite,Texas',
  '31.9973,-102.0779,Midland,Texas',
  '26.2159,-98.3253,Mission,Texas',
  '31.6039,-94.6560,Nacogdoches,Texas',
  '29.7026,-98.1241,New Braunfels,Texas',
  '31.8457,-102.3676,Odessa,Texas',
  '30.0930,-93.7366,Orange,Texas',
  '35.5362,-100.9599,Pampa,Texas',
  '33.6609,-95.5555,Paris,Texas',
  '29.6911,-95.2091,Pasadena,Texas',
  '31.4229,-103.4932,Pecos,Texas',
  '26.1948,-98.1836,Pharr,Texas',
  '34.1848,-101.7068,Plainview,Texas',
  '33.0198,-96.6989,Plano,Texas',
  '29.8850,-93.9399,Port Arthur,Texas',
  '28.6150,-96.6261,Port Lavaca,Texas',
  '32.9483,-96.7299,Richardson,Texas',
  '31.4638,-100.4370,San Angelo,Texas',
  '29.4252,-98.4946,San Antonio,Texas',
  '29.7932,-96.1010,San Felipe,Texas',
  '29.8833,-97.9414,San Marcos,Texas',
  '33.6357,-96.6089,Sherman,Texas',
  '32.4710,-100.4059,Sweetwater,Texas',
  '31.0982,-97.3428,Temple,Texas',
  '33.4251,-94.0477,Texarkana,Texas',
  '29.3838,-94.9027,Texas City,Texas',
  '32.3513,-95.3011,Tyler,Texas',
  '29.2097,-99.7862,Uvalde,Texas',
  '28.8053,-97.0036,Victoria,Texas',
  '31.5493,-97.1467,Waco,Texas',
  '32.7593,-97.7973,Weatherford,Texas',
  '33.9137,-98.4934,Wichita Falls,Texas',
  '31.6984,-106.3250,Ysleta,Texas',
  '40.5888,-111.6380,Alta,Utah',
  '40.3769,-111.7958,American Fork,Utah',
  '40.8894,-111.8808,Bountiful,Utah',
  '41.5102,-112.0155,Brigham City,Utah',
  '37.6775,-113.0619,Cedar City,Utah',
  '41.1108,-112.0261,Clearfield,Utah',
  '39.3522,-112.5772,Delta,Utah',
  '38.9639,-112.3397,Fillmore,Utah',
  '38.9956,-110.1596,Green River,Utah',
  '40.5070,-111.4133,Heber City,Utah',
  '37.0475,-112.5263,Kanab,Utah',
  '41.0602,-111.9711,Layton,Utah',
  '40.3916,-111.8508,Lehi,Utah',
  '41.7370,-111.8338,Logan,Utah',
  '39.2683,-111.6369,Manti,Utah',
  '38.5733,-109.5498,Moab,Utah',
  '37.8714,-109.3429,Monticello,Utah',
  '40.6669,-111.8880,Murray,Utah',
  '39.7102,-111.8363,Nephi,Utah',
  '41.2230,-111.9738,Ogden,Utah',
  '37.2758,-112.6377,Orderville,Utah',
  '40.2969,-111.6946,Orem,Utah',
  '37.8228,-112.4358,Panguitch,Utah',
  '40.6461,-111.4980,Park City,Utah',
  '40.0444,-111.7322,Payson,Utah',
  '39.5994,-110.8107,Price,Utah',
  '40.2338,-111.6585,Provo,Utah',
  '37.0965,-113.5684,Saint George,Utah',
  '40.7608,-111.8910,Salt Lake City,Utah',
  '40.1150,-111.6549,Spanish Fork,Utah',
  '40.1652,-111.6108,Springville,Utah',
  '40.5308,-112.2983,Tooele,Utah',
  '40.4555,-109.5287,Vernal,Utah',
  '44.1970,-72.5020,Barre,Vermont',
  '43.1334,-72.4440,Bellows Falls,Vermont',
  '42.8781,-73.1968,Bennington,Vermont',
  '42.8509,-72.5579,Brattleboro,Vermont',
  '44.4759,-73.2121,Burlington,Vermont',
  '44.4905,-73.1112,Essex,Vermont',
  '43.1637,-73.0723,Manchester,Vermont',
  '44.0153,-73.1673,Middlebury,Vermont',
  '44.2601,-72.5754,Montpelier,Vermont',
  '44.9364,-72.2051,Newport,Vermont',
  '43.5354,-72.7220,Plymouth,Vermont',
  '43.6106,-72.9726,Rutland,Vermont',
  '44.8107,-73.0836,Saint Albans,Vermont',
  '44.4193,-72.0151,Saint Johnsbury,Vermont',
  '43.7852,-72.4542,Sharon,Vermont',
  '44.4914,-73.1857,Winooski,Vermont',
  '36.7098,-81.9773,Abingdon,Virginia',
  '38.8048,-77.0469,Alexandria,Virginia',
  '36.5951,-82.1887,Bristol,Virginia',
  '38.0293,-78.4767,Charlottesville,Virginia',
  '36.7682,-76.2875,Chesapeake,Virginia',
  '36.5860,-79.3950,Danville,Virginia',
  '38.8462,-77.3064,Fairfax,Virginia',
  '38.8823,-77.1711,Falls Church,Virginia',
  '38.3032,-77.4605,Fredericksburg,Virginia',
  '37.0299,-76.3452,Hampton,Virginia',
  '37.7666,-77.3702,Hanover,Virginia',
  '37.3043,-77.2872,Hopewell,Virginia',
  '37.7840,-79.4428,Lexington,Virginia',
  '37.4138,-79.1422,Lynchburg,Virginia',
  '38.7509,-77.4753,Manassas,Virginia',
  '36.6915,-79.8725,Martinsville,Virginia',
  '38.6479,-78.6714,New Market,Virginia',
  '37.0871,-76.4730,Newport News,Virginia',
  '36.8508,-76.2859,Norfolk,Virginia',
  '37.2279,-77.4019,Petersburg,Virginia',
  '36.8354,-76.2983,Portsmouth,Virginia',
  '38.9586,-77.3570,Reston,Virginia',
  '37.5407,-77.4360,Richmond,Virginia',
  '37.2710,-79.9414,Roanoke,Virginia',
  '38.1496,-79.0717,Staunton,Virginia',
  '36.7282,-76.5836,Suffolk,Virginia',
  '36.8516,-75.9792,Virginia Beach,Virginia',
  '38.0685,-78.8895,Waynesboro,Virginia',
  '37.2707,-76.7075,Williamsburg,Virginia',
  '39.1857,-78.1633,Winchester,Virginia',
  '46.9754,-123.8157,Aberdeen,Washington',
  '48.5126,-122.6127,Anacortes,Washington',
  '47.3073,-122.2285,Auburn,Washington',
  '47.6101,-122.2015,Bellevue,Washington',
  '48.7519,-122.4787,Bellingham,Washington',
  '47.5650,-122.6270,Bremerton,Washington',
  '46.7162,-122.9543,Centralia,Washington',
  '47.9654,-118.9761,Coulee Dam,Washington',
  '48.2198,-122.6863,Coupeville,Washington',
  '46.9965,-120.5478,Ellensburg,Washington',
  '47.3176,-119.5536,Ephrata,Washington',
  '47.9790,-122.2021,Everett,Washington',
  '46.9770,-123.8839,Hoquiam,Washington',
  '46.1468,-122.9084,Kelso,Washington',
  '46.2087,-119.1199,Kennewick,Washington',
  '46.1382,-122.9382,Longview,Washington',
  '47.1301,-119.2781,Moses Lake,Washington',
  '48.2883,-122.6484,Oak Harbor,Washington',
  '47.0379,-122.9007,Olympia,Washington',
  '46.2305,-119.0922,Pasco,Washington',
  '48.9884,-123.0569,Point Roberts,Washington',
  '48.1181,-123.4307,Port Angeles,Washington',
  '46.7298,-117.1817,Pullman,Washington',
  '47.1854,-122.2929,Puyallup,Washington',
  '47.6740,-122.1215,Redmond,Washington',
  '47.4797,-122.2079,Renton,Washington',
  '46.2804,-119.2752,Richland,Washington',
  '47.6062,-122.3321,Seattle,Washington',
  '47.6588,-117.4260,Spokane,Washington',
  '47.2529,-122.4443,Tacoma,Washington',
  '45.6280,-122.6739,Vancouver,Washington',
  '46.0646,-118.3430,Walla Walla,Washington',
  '47.4235,-120.3103,Wenatchee,Washington',
  '46.6021,-120.5059,Yakima,Washington',
  '39.6270,-78.2272,Bath,West Virginia',
  '37.7782,-81.1882,Beckley,West Virginia',
  '37.2698,-81.2223,Bluefield,West Virginia',
  '38.9940,-80.2320,Buckhannon,West Virginia',
  '39.2890,-77.8597,Charles Town,West Virginia',
  '38.3498,-81.6326,Charleston,West Virginia',
  '39.2806,-80.3445,Clarksburg,West Virginia',
  '38.9259,-79.8467,Elkins,West Virginia',
  '39.4851,-80.1426,Fairmont,West Virginia',
  '39.3409,-80.0190,Grafton,West Virginia',
  '39.3254,-77.7389,Harpers Ferry,West Virginia',
  '38.1354,-80.2131,Hillsboro,West Virginia',
  '37.6740,-80.8893,Hinton,West Virginia',
  '38.4192,-82.4452,Huntington,West Virginia',
  '39.4409,-78.9739,Keyser,West Virginia',
  '37.8018,-80.4456,Lewisburg,West Virginia',
  '37.8487,-81.9935,Logan,West Virginia',
  '39.4562,-77.9639,Martinsburg,West Virginia',
  '39.6295,-79.9559,Morgantown,West Virginia',
  '39.9204,-80.7431,Moundsville,West Virginia',
  '39.6445,-80.8576,New Martinsville,West Virginia',
  '39.2667,-81.5615,Parkersburg,West Virginia',
  '39.1523,-80.0404,Philippi,West Virginia',
  '38.8445,-82.1371,Point Pleasant,West Virginia',
  '37.3662,-81.1026,Princeton,West Virginia',
  '39.3420,-78.7567,Romney,West Virginia',
  '39.4301,-77.8042,Shepherdstown,West Virginia',
  '38.3686,-81.6999,South Charleston,West Virginia',
  '38.2812,-80.8526,Summersville,West Virginia',
  '40.4190,-80.5895,Weirton,West Virginia',
  '37.4329,-81.5846,Welch,West Virginia',
  '40.2720,-80.6095,Wellsburg,West Virginia',
  '39.0384,-80.4673,Weston,West Virginia',
  '40.0640,-80.7209,Wheeling,West Virginia',
  '37.7965,-80.2976,White Sulphur Springs,West Virginia',
  '37.6743,-82.2774,Williamson,West Virginia',
  '44.2619,-88.4154,Appleton,Wisconsin',
  '46.5924,-90.8838,Ashland,Wisconsin',
  '43.4711,-89.7443,Baraboo,Wisconsin',
  '42.7361,-90.3343,Belmont,Wisconsin',
  '42.5083,-89.0318,Beloit,Wisconsin',
  '44.8113,-91.4985,Eau Claire,Wisconsin',
  '43.7730,-88.4471,Fond du Lac,Wisconsin',
  '44.5133,-88.0133,Green Bay,Wisconsin',
  '46.0130,-91.4846,Hayward,Wisconsin',
  '42.6828,-89.0187,Janesville,Wisconsin',
  '42.5847,-87.8212,Kenosha,Wisconsin',
  '43.8138,-91.2519,La Crosse,Wisconsin',
  '42.5917,-88.4334,Lake Geneva,Wisconsin',
  '43.0722,-89.4008,Madison,Wisconsin',
  '44.0886,-87.6576,Manitowoc,Wisconsin',
  '45.1000,-87.6307,Marinette,Wisconsin',
  '44.2022,-88.4465,Menasha,Wisconsin',
  '43.0389,-87.9065,Milwaukee,Wisconsin',
  '44.1858,-88.4626,Neenah,Wisconsin',
  '42.8144,-89.6351,New Glarus,Wisconsin',
  '44.8872,-87.8645,Oconto,Wisconsin',
  '44.0247,-88.5426,Oshkosh,Wisconsin',
  '45.0544,-87.7493,Peshtigo,Wisconsin',
  '43.5391,-89.4626,Portage,Wisconsin',
  '43.0517,-91.1412,Prairie du Chien,Wisconsin',
  '42.7261,-87.7829,Racine,Wisconsin',
  '45.6366,-89.4121,Rhinelander,Wisconsin',
  '43.8422,-88.8359,Ripon,Wisconsin',
  '43.7508,-87.7145,Sheboygan,Wisconsin',
  '43.1753,-90.0679,Spring Green,Wisconsin',
  '44.5236,-89.5746,Stevens Point,Wisconsin',
  '44.8342,-87.3770,Sturgeon Bay,Wisconsin',
  '46.7208,-92.1041,Superior,Wisconsin',
  '43.0108,-88.2292,Waukesha,Wisconsin',
  '44.9591,-89.6301,Wausau,Wisconsin',
  '43.0495,-88.0076,Wauwatosa,Wisconsin',
  '43.0167,-88.0070,West Allis,Wisconsin',
  '43.4253,-88.1834,West Bend,Wisconsin',
  '43.6275,-89.7710,Wisconsin Dells,Wisconsin',
  '44.3483,-106.6989,Buffalo,Wyoming',
  '42.8487,-106.2981,Casper,Wyoming',
  '41.1400,-104.8202,Cheyenne,Wyoming',
  '44.5263,-109.0565,Cody,Wyoming',
  '42.7597,-105.3822,Douglas,Wyoming',
  '41.2683,-110.9632,Evanston,Wyoming',
  '44.2911,-105.5022,Gillette,Wyoming',
  '41.5286,-109.4662,Green River,Wyoming',
  '43.4799,-110.7624,Jackson,Wyoming',
  '42.8330,-108.7307,Lander,Wyoming',
  '41.3114,-105.5911,Laramie,Wyoming',
  '43.8547,-104.2049,Newcastle,Wyoming',
  '44.7538,-108.7574,Powell,Wyoming',
  '41.7911,-107.2387,Rawlins,Wyoming',
  '43.0247,-108.3806,Riverton,Wyoming',
  '41.5875,-109.2029,Rock Springs,Wyoming',
  '44.7972,-106.9562,Sheridan,Wyoming',
  '44.0341,-107.4512,Ten Sleep,Wyoming',
  '43.6461,-108.2120,Thermopolis,Wyoming',
  '42.0625,-104.1844,Torrington,Wyoming',
  '44.0169,-107.9554,Worland,Wyoming',
]

class State {
  name: String;
  cities: City[];
  constructor(name: String, cities: City[]) {
    this.name = name;
    this.cities = cities;
  }
}

export default class City {
  lat: number
  lng: number
  cityName: String
  stateName: String
  constructor(lat: number, lng: number, cityName: String, stateName: String) {
      this.lat = lat;
      this.lng = lng;
      this.cityName = cityName;
      this.stateName = stateName;
  }
}

export const states: State[] = []

locations.forEach((s) => {
  const parts = s.split(",");
  const lat = parseFloat(parts[0]);
  const lng = parseFloat(parts[1]);
  const cityName = parts[2];
  const stateName = parts[3];
  // Find the state in the states array
  let state = states.find((st) => st.name === stateName);
  // If the state doesn't exist, create a new State object and add it to the states array
  if (!state) {
    state = new State(stateName, []);
    states.push(state);
  }
  // Create a new City object and add it to the cities array within the State object
  const city = new City(lat, lng, cityName, stateName);
  state.cities.push(city);
});
