import React, { useRef, useState } from 'react';
import { states } from '../repo/Locations';

let isSuggestionClicked = false

function LocationDropdownEditbox(p) {
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef(null);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    p.setSearch(inputValue);
    const fetchedSuggestions = states.filter((suggestion) =>
      suggestion.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setSuggestions(fetchedSuggestions);
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (suggestion) => {
    isSuggestionClicked = true
    inputRef.current.focus();

    let containsAll = suggestion.cities.filter((l) => {
      for (let i in p.locations) {
        if (p.locations[i].cityName === l.cityName && p.locations[i].state === l.state)
          return false
      }
      return true
    }).length === 0

    const removedList = p.locations.filter((l) => {
      for (let i in suggestion.cities) {
        if (suggestion.cities[i].cityName === l.cityName && suggestion.cities[i].state === l.state)
          return false
      }
      return true
    })
    
    if (containsAll)
      p.setLocations(removedList)
    else
      p.setLocations([...suggestion.cities, ...removedList])
  };

  const handleCityClick = (city) => {
    isSuggestionClicked = true
    inputRef.current.focus();

    let containsCity = false
    const newList = p.locations.filter((l) => {
      if (l === city) {
        containsCity = true
        return false
      }
      return true
    })
    if (containsCity)
      p.setLocations(newList)
    else
      p.setLocations([city, ...p.locations])
  }  

  return (
    <div className="autocomplete">
      <input
        type="text"
        ref={inputRef}
        value={p.search}
        onChange={handleInputChange}
        onBlur={() => setTimeout(() => {
          if (!isSuggestionClicked)
            setShowSuggestions(false)
          isSuggestionClicked = false
        }, 200)
        }
        onClick={() => setShowSuggestions(true)}
        placeholder="Enter State Name..."
      />
      {showSuggestions && (
        <ul className="suggestion-list">
          {suggestions.map((suggestion, index) => (
            <li key={index}>
              <div onClick={() => handleSuggestionClick(suggestion)} className="suggestion-item">{suggestion.name}</div>
              <ul>
                {suggestion.cities.map((s) => (
                  <li className='suggestion-item' onClick={() => handleCityClick(s)}>{s.cityName}</li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default LocationDropdownEditbox;
