import { useState } from "react"
import { useAuth } from "../contexts/AuthContext"
import Header from "../Header"

export default function AuthLink() {
  const { user, checkIfSignInWithEmail } = useAuth()

  if (user) {
    window.location.href = "../leads"
  }

  useState(() => {
    setTimeout(async () => {
      const stuff = await checkIfSignInWithEmail()
      console.log(stuff)
    }, 0)
  }, [])

  return (
    <div style={{ minHeight: "100vh", background: "#013e06", minWidth: "100vw" }}>
      <Header/>
    </div>
  )
}