import { Checkbox } from "../components/OrderView"
import City from "./Locations"

export default class Order {
    id: String
    date: string
    error: any | null
    leads: string | null
    isComplete: boolean
    locations: City[]
    query: string
    constructor(id: String, date: string, error: any, leads: any, isComplete: boolean, locations: City[], query: string) {
        this.id = id
        this.date = date
        this.error = error
        this.leads = leads ? JSON.stringify(leads) : null
        this.isComplete = isComplete
        this.locations = locations
        this.query = query
    }

}

export function formatOrdersJson(json: any): Order[] {
    const orders = json.data.map((order: any) => {
        return new Order(order._id, order.date, order.error, order.leads, order.isComplete, order.locations, order.query)
    })
    return orders
}

export function formatOrderJson(json: any): Order {
    return new Order(json._id, json.date, json.error, json.leads, json.isComplete, json.locations, json.query)
}

export class Sort {
    type: SortType
    name: String
    active: boolean
    constructor(type: SortType, name: string, active: boolean = false) {
        this.type = type
        this.name = name
        this.active = active
    }
}

export enum SortType {
    DEFAULT_SORT,
    FIRST_TO_LAST_RESULTS,
    REVERSE_SORT,
    RATING_HIGH_TO_LOW,
    RATING_LOW_TO_HIGH,
    REVIEW_COUNT_HIGH_TO_LOW,
    REVIEW_COUNT_LOW_TO_HIGH
}

function getSorts(): Sort[] {
    const sorts = []
    sorts.push(new Sort(SortType.DEFAULT_SORT, "Order by Location"))
    sorts.push(new Sort(SortType.FIRST_TO_LAST_RESULTS, "Top Results on Google", true))
    sorts.push(new Sort(SortType.REVERSE_SORT, "Lowest Results on Google"))
    sorts.push(new Sort(SortType.RATING_HIGH_TO_LOW, "Sort by ratings high to low"))
    sorts.push(new Sort(SortType.RATING_LOW_TO_HIGH, "Sort by ratings low to high"))
    sorts.push(new Sort(SortType.REVIEW_COUNT_HIGH_TO_LOW, "Sort by review count high to low"))
    sorts.push(new Sort(SortType.REVIEW_COUNT_LOW_TO_HIGH, "Sort by reivew count low to high"))
    return sorts
}

export class Filter {
    type: FilterType
    name: String
    active: boolean
    constructor(type: FilterType, name: string, active: boolean = false) {
        this.type = type
        this.name = name
        this.active = active
    }
}

export enum FilterType {
    HAS_NUMBER,
    HAS_EMAIL,
    HAS_WEBSITE,
    NO_WEBSITE,
    NO_DUPLICATES,
}

function getFilters(): Filter[] {
    const filters = []
    filters.push(new Filter(FilterType.HAS_NUMBER, "Has Number"))
    filters.push(new Filter(FilterType.HAS_EMAIL, "Has Email"))
    filters.push(new Filter(FilterType.HAS_WEBSITE, "Has Website"))
    filters.push(new Filter(FilterType.NO_WEBSITE, "No Website"))
    filters.push(new Filter(FilterType.NO_DUPLICATES, "Hide Duplicates", true))
    return filters
}

export class LeadSifter {
    filters: Filter[]
    sorts: Sort[]
    visibleFields: string[]
    hiddenFields: string[]

    constructor(_leadSifter: LeadSifter | null = null) {
        const leadSifter = _leadSifter === null || _leadSifter.filters == null || _leadSifter.filters.length !== 5 ? null : _leadSifter
        this.filters = leadSifter ? leadSifter.filters : getFilters()
        this.sorts = leadSifter ? leadSifter.sorts : getSorts()
        this.visibleFields = leadSifter ? leadSifter.visibleFields : [
          "name","category", "phone", "site", "full_address", "working_hours", "time_zone", "rating", "result_number",
        ]
        this.hiddenFields = leadSifter ? leadSifter.hiddenFields : [
          "email_1", "email_1_full_name", "email_1_title","phone_1",
          "email_2", "email_2_full_name", "email_2_title","phone_2",
          "email_3", "email_3_full_name", "email_3_title", "phone_3",
          "facebook", "instagram", "linkedin", "twitter", "youtube",  "business_status",
          "company_year_started", "parent_company", "telephone", "telephone2", 
          "sales_volume", "sales_code", "number_of_employees",
          "about","area_service","booking_appointment_link","borough",
            "city", "country", "country_code","description", "latitude",
            "located_google_id","located_in","location_link","logo","longitude",
            "menu_link","order_links", "other_hours","owner_link","owner_title",
            "photo","photos_count", "plus_code","popular_times","postal_code", "posts",
            "range","reservation_links", "reviews_id","reviews_link","reviews_tags", "verified",
            "state","street","street_view","type","subtypes","us_state","ethnicity","reviews_per_score"
          ];
    }
    activeSort(): Sort {
        for (let i in this.sorts) {
            if (this.sorts[i].active)
                return this.sorts[i]
        }
        return this.sorts[0]
    }
}

export class TextField {
    isVisible: boolean
    text: string
    onClick: () => void
    constructor(isVisible: boolean, text: string, onClick: () => void) {
        this.isVisible = isVisible
        this.text = text
        this.onClick = onClick
    }
}

export class Lead {
    values: any[]
    constructor(values: any[]) {
        this.values = values
    }
}

export const formatLeadsFile = (raw: any | null, leadSifter: LeadSifter, locations: Checkbox[]): Lead[] | null => {
    if (!raw || locations === null) return null
    let rawLeads: any[] = raw
    switch (leadSifter.activeSort().type) {
      case SortType.FIRST_TO_LAST_RESULTS: {
          rawLeads.sort((a, b) => {
            let aCompare = a['result_number']
            let bCompare = b['result_number']
            aCompare = aCompare ? aCompare : 0
            bCompare = bCompare ? bCompare : 0
            return aCompare - bCompare
          });
          break;
      }
      case SortType.REVERSE_SORT: {
          rawLeads.sort((a, b) => {
            let aCompare = a['result_number']
            let bCompare = b['result_number']
            aCompare = aCompare ? aCompare : 0
            bCompare = bCompare ? bCompare : 0
            return bCompare - aCompare
          });
          break;
      }
      case SortType.RATING_HIGH_TO_LOW: {
        rawLeads.sort((a, b) => {
          let aCompare = a['rating']
          let bCompare = b['rating']
          aCompare = aCompare ? aCompare : 0
          bCompare = bCompare ? bCompare : 0
          return bCompare - aCompare
        });
        break;
      }
      case SortType.RATING_LOW_TO_HIGH:
        rawLeads.sort((a, b) => {
          let aCompare = a['rating']
          let bCompare = b['rating']
          aCompare = aCompare ? aCompare : 0
          bCompare = bCompare ? bCompare : 0
          return aCompare - bCompare
        });
        break;
      case SortType.REVIEW_COUNT_HIGH_TO_LOW:
        rawLeads.sort((a, b) => {
          let aReview = a['reviews']
          let bReview = b['reviews']
          aReview = aReview ? aReview : 0
          bReview = bReview ? bReview : 0
          return bReview - aReview
        });
        break;
      case SortType.REVIEW_COUNT_LOW_TO_HIGH:
        rawLeads.sort((a, b) => {
          let aReview = a['reviews']
          let bReview = b['reviews']
          aReview = aReview ? aReview : 0
          bReview = bReview ? bReview : 0
          return aReview - bReview
        });
        break;
      default:
        // For the DEFAULT_SORT case, do nothing as it's the default state of the array.
        break;
    }
    for (let i in locations) {
      locations[i].count = 0
      locations[i].visibleCount = 0
    }
    if (locations.length > 0)
        rawLeads = rawLeads.filter((r) => {
            for (let i in locations) {
                const l = locations[i]
                const isChecked = l.isChecked
                const query = `${l.city.cityName.replace('-', '')} ${l.city.stateName}`
                if (r["query"].includes(query)) {
                    l.count += 1
                    return isChecked
                }
            }
            return true
        })
    const ids = new Set();
    rawLeads = rawLeads.filter((l) => {
      for (let i in leadSifter.filters) {
        const filter = leadSifter.filters[i]
        if (!filter.active) continue
        switch (filter.type) {
          case FilterType.HAS_EMAIL: {
            const email = l["email_1"]
            if (!email) return false
            break
          }
          case FilterType.HAS_NUMBER: {
            const phoneIdx = l["phone"]
            if (!phoneIdx) return false
            break
          }
          case FilterType.HAS_WEBSITE: {
            const siteIdx = l["site"]
            if (!siteIdx) return false
            break
          }
          case FilterType.NO_WEBSITE: {
            const siteIdx = l["site"]
            if (siteIdx) return false
            break
          }
          case FilterType.NO_DUPLICATES: {
            if (ids.has(l["google_id"]))
              return false
            ids.add(l['google_id'])
            break;
          }
        }
      }
      return true
    })

    if (locations.length > 0)
      rawLeads.forEach((r) => {
        for (let i in locations) {
            const l = locations[i]
            const query = `${l.city.cityName.replace('-', '')} ${l.city.stateName}`
            if (r["query"].includes(query)) {
                l.visibleCount += 1
                return
            }
        }
        return
    })

    let parsedLeads: Lead[] = []
    rawLeads.forEach((lead, index) => {
        const values: (any | null)[] = []
        leadSifter.visibleFields.forEach((param, index) => {
            values[index] = lead[param];
        });
        parsedLeads = [...parsedLeads, new Lead(values)]
    });
    return parsedLeads
  };
