import React from 'react'
import {  Sort } from '../repo/Order'

interface SortProps {
    sort: Sort,
    apply: () => void
}

export const SortView: React.FC<SortProps> = ({ sort, apply }) => {
    const isApplied = sort.active;
  
    return (
      <div onClick={apply} key={`${sort.name}`} style={isApplied ? appliedSortItemStyle : applySortItemStyle}>
        <h3 style={sortText}>{sort.name}</h3>
      </div>
    );
}

const applySortItemStyle: React.CSSProperties = {
    border: "1px solid black",
    marginTop: '1px',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '8px',
    paddingBottom: '8px',
    background: '#eaffe3',
    cursor: 'pointer',
  };

const appliedSortItemStyle: React.CSSProperties = {
    border: "1px solid green",
    marginTop: '1px',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '8px',
    paddingBottom: '8px',
    background: '#b0ff8f',
    textDecoration: 'underline',
    color: 'black',
    cursor: 'pointer',
  };

const sortText: React.CSSProperties = {
    margin: '0px',
    color: 'black'
  };
