import React from 'react'

interface CheckboxProps {
    isChecked: boolean
    text: string
    toggle: () => void
}

export const CheckboxView: React.FC<CheckboxProps> = ({ isChecked, text, toggle }) => {

    const handleCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
    };
  
    return (
      <div onClick={toggle} key={text} style={checkboxItemStyle}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxClick}
          style={checkboxStyle}
        />
        <h3 className='plainBlackText'>{text}</h3>
      </div>
    );
}

const checkboxItemStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center', // Vertically center the checkbox and text
    cursor: 'pointer',
  };
  
  const checkboxStyle: React.CSSProperties = {
    marginRight: '8px', // Add some spacing between the checkbox and text
    marginTop: '6px'
  };

