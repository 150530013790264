import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Map from '../components/Map'
import { getAuth } from 'firebase/auth'
import City, { states } from '../repo/Locations'
import LocationLook from '../components/LocationLook'
import { NetworkState } from '../repo/NetworkState'
import LocationDropdownEditbox from '../components/LocationDropdownEditbox copy'
import CustomDropdownEditbox from '../components/CustomDropdownEditbox'
import { useAuth } from '../contexts/AuthContext'
import Header from '../Header'
import { Alert } from 'react-bootstrap'
import { UserData } from '../repo/User'

export default function Leads() {
  const { user } = useAuth()
  const [locations, setLocations] = React.useState<City[]>(user ? user.locations : [])
  const [business, setBusiness] = React.useState<string>(user ? user.query : "")
  const [locationSearch, setLocationSearch] = React.useState<string>("")
  const [networkState, setNetworkState] = React.useState<NetworkState>(new NetworkState())

  if (!user) {
    window.location.href = '/log-in'
  }

  useEffect(() => {
    setLocations(user.locations)
    setBusiness(user.query)
  }, [user]);
  return (
    <>
    <Header />
    <div style={containerStyle}>
      <div style={menuStyle}>
        <div style={layoutContainer}>
          <h2 style={blackText}>Business Category</h2>
          <CustomDropdownEditbox search={business} setSearch={setBusiness}/>
        
          <p style={blackText}>You can enter any business you want. The prefilled options will return more specific results.</p>
        </div>
    
        <div style={layoutContainer}>
          <h2 style={blackText}>Locations to Search</h2>
          <LocationDropdownEditbox search={locationSearch} setSearch={setLocationSearch} locations={locations} setLocations={setLocations}/>
          <p style={blackText}>Click map or search locations by State.</p>
          {locations.length > 0 && <div style={{ display: 'inline-flex', alignItems: 'center' }}>
            <h2 style={{ margin: 0 }}>{locations.length} Search{locations.length !== 1 ? "es" : ""}</h2>
            <p style={{ margin: 0, marginLeft: '10px', cursor: 'pointer' }} onClick={() => { setLocations([]) }}>clear all</p>
          </div> }
          {locations.length > 0 && 
          <div style={locationsContainer}>
              {locations.map((l) => (
                <LocationLook city={l} searchTerm={business}/>
              ))}
          </div>}
          
       </div>

       <div style={layoutContainer}>
          <h2 style={blackText}>Get All Leads</h2>
          <p >* Enter in a business category.</p>
          <p >* Place at least one location pin on the map.</p>
          <p>* It's recommened to have 50 credits per location you search.</p>
          <p className='mt-2 mb-0' style={{cursor: "pointer", width: '100%', textAlign: "center", textDecoration: "underline" }} onClick={() => goToPricing(user, locations, business)}>You have {user.credits} Laplead Credits. {user.escrow_credits > 0 ? `(${user.escrow_credits} credits looking for leads)` : ''}</p>
          {networkState.isLoading ? <h2 style={blackText}>Loading...</h2> : null}
          { locations.length === 0 ? <div/> : user.credits === 0 ? 
            <Alert variant="danger">You have 0 available credits {user.escrow_credits > 0 ? `(${user.escrow_credits} credits looking for leads)` : ''}, to get more <Link to="../pricing"  onClick={() => goToPricing(user, locations, business)}>buy more credits</Link>.</Alert> :
            <div className='text-center'>
              <button disabled={business.length === 0} className="roundedButtonStyle" onClick={() => user.credits === 0 ? goToPricing(user, locations, business) : gatherLeads(business, locations, networkState, setNetworkState)}>Get all {business} from {locations.length} search{locations.length > 1 ? "es" : ""}</button>
              {business.length === 0 && locations.length > 0 && <Alert variant="danger">Enter the business category you want.</Alert>}
            </div>
          }
        </div>

        <p>Need help? Email <a href="mailto:justin@fantasma.dev">justin@fantasma.dev</a></p>

      </div>
      <div style={contentStyle}>
        <Map locations={locations} setLocations={setLocations} uid={user.uid} getNearestCity={getNearestCity}/>
      </div>
    </div>
    </>
  )
}

const gatherLeads = (query: String, locations: City[], networkState: NetworkState, setNetworkState: React.Dispatch<React.SetStateAction<NetworkState>>) => {
  if (networkState.isLoading) return
  setNetworkState(new NetworkState(true))
  getAuth().currentUser?.getIdToken().then((token) => {
    fetch(process.env.REACT_APP_SERVER_URL + `/api`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
      body: JSON.stringify({
        query: query,
        locations: locations,
      }),
    })
    .then((response) => response.json())
    .then((json) => {
       window.location.href = '/my-leads';
    })
    .catch((error) => {
      console.error('Error:', error);
      // Handle error if the request fails
    });
  })
}

const goToPricing = (user: UserData | null, locations: City[], query: string) => {
  if (user) {
    getAuth().currentUser?.getIdToken().then((token) => {
      fetch(process.env.REACT_APP_SERVER_URL + `/updateUser`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          query: query,
          locations: locations,
        }),
      })
      .then((response) => response.json())
      .then((json) => {
        window.location.href = '/pricing'
      })
      .catch((error) => {
          console.error('Error:' , error);
      });
    })
  }
  else
    window.location.href = '/pricing'
}

const getNearestCity = (latitude: number, longitude: number): City | null => {
  let closestCity: City | null = null;
  let minDistance: number = Number.MAX_VALUE;

  states.forEach((state) => {
    state.cities.forEach((city) => {
  
      // Calculate the distance between the current city and the target coordinates
      const distance: number = calculateDistance(latitude, longitude, city.lat, city.lng);
  
      // Update the closestCity and minDistance if the current city is closer
      if (distance < minDistance) {
        closestCity = city;
        minDistance = distance;
      }
    });
  })

  return closestCity;
}

// Helper function to calculate the distance between two sets of coordinates using the Haversine formula
function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const earthRadius: number = 6371; // Radius of the Earth in kilometers

  const dLat: number = toRadians(lat2 - lat1);
  const dLon: number = toRadians(lon2 - lon1);

  const a: number =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c: number = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance: number = earthRadius * c;

  return distance;
}

// Helper function to convert degrees to radians
function toRadians(degrees: number): number {
  return (degrees * Math.PI) / 180;
}

// CSS styles
const containerStyle: React.CSSProperties = {
  display: 'flex',
  height: 'calc(100vh - 82px)',
};

const menuStyle: React.CSSProperties = {
  flex: '0 0 20%', // The menu takes 20% of the available width
  minWidth: '200px', // Set a minimum width of 200px
  background: '#d4e3d5', // Menu background color
  overflowY: 'auto', // Enable vertical scrolling if content exceeds the viewport height
  padding: '12px',
  border: '1px black'
};

const blackText: React.CSSProperties = {
  color: 'black',
  marginTop: '10px',
}

const contentStyle: React.CSSProperties = {
  flex: '1', // The content takes up the remaining available width
  overflow: 'hidden', // Hide any overflow to prevent unnecessary scrollbars
};

const layoutContainer: React.CSSProperties = {
  background: 'white',
  borderRadius: '10px',
  padding: '20px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Add a drop shadow
  marginBottom: '16px'
};


const locationsContainer: React.CSSProperties = {
  maxHeight: '400px',
  overflowY: 'auto',
  border: '1px solid #d4d4d4'
};
