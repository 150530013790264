import React from 'react';
import logo from './logo.png'; // Import the logo image
import { useAuth } from './contexts/AuthContext';

const Header: React.FC = () => {
    const { user, logout } = useAuth()

    function onLoginClick() {
      window.location.href = "/log-in"
    }

    let Profile
    if (user == null)
      Profile = <button onClick={onLoginClick} style={loginButtonStyle(false, true)}>Login</button>
    else
      Profile = <button onClick={logout} style={loginButtonStyle(false, true)}>Log Out</button>

    const navigate = (path: string) => {
      window.location.href = path
    }

  return (
    <header style={headerStyle}>
      <div style={logoContainerStyle}>
        <a href='../'>
        <img src={logo} alt="Laplead" style={logoStyle} />
        </a>
      </div>
      <div>
      <div style={signedInButtons}>
        { user && <button onClick={() => navigate('/leads')} style={loginButtonStyle(window.location.href.includes("/leads"))}>Let's Collect Leads</button>}
      </div>
      <div style={signedInButtons}>
        { user && <button onClick={() => navigate('/my-leads')} style={loginButtonStyle(window.location.href.includes("/my-leads"))}>My Collected Leads</button>}
      </div>
      <div style={signedInButtons}>
        {Profile}
      </div>
      </div>
    </header>
  );
};

// Define the styles for the header, logo, and login button
const headerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '41px',
  background: '#013e06',
  height: 'auto', // Changed from '50px' to 'auto' to allow the header to expand vertically
  flexWrap: 'wrap', // Added this line
};

const logoContainerStyle: React.CSSProperties = {
  marginRight: 'auto',
};

const logoStyle: React.CSSProperties = {
  width: '200px',
  height: 'auto',
};

const loginButtonStyle = (isSelected = false, isLogin = false) => {
    const backgroundColor = isSelected ? '#325725' : 'transparent'
    const textDecordation = isSelected ? 'underline' : 'none'
    const style: React.CSSProperties = {
      padding: '8px 16px',
      background: backgroundColor,
      border: `1px solid #abf880`,
      borderRadius: '4px',
      color: `#abf880`,
      cursor: 'pointer',
      transition: 'border-color 0.3s, background-color 0.3s',
      fontSize: '1.5em',
      margin: '0',
      display: 'inline-block',
      position: 'relative',
      zIndex: 1,
      overflow: 'hidden',
      boxShadow: 'none',
      outline: 'none',
      whiteSpace: 'nowrap',
      marginLeft: '10px',
      textDecoration: textDecordation,
    }
   return style
  };

  const signedInButtons: React.CSSProperties = {
    display: "inline-block",
    alignItems: "center",
    marginRight: "8px"
  }
  

export default Header;
