import { useRef, useState } from "react"
import { Form, Card, Alert, Container } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import Header from "../Header"

export default function Signup() {
  const emailRef = useRef()
  const { loginWithEmail, user } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [emailSent, setEmailSent] = useState(false)

  if (user) {
    window.location.href = "../leads"
  }

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
      const email = emailRef.current.value
      loginWithEmail(email).then(() => {
        setEmailSent(true)
      })
      .catch((error) => {
        var errorMessage = error.message
        console.log(errorMessage)
      })
    } catch(e) {
      setError("Failed to create an account. " + e.message.replace("Firebase: ", ""))
    }

    setLoading(false)
  }

  return (
    <div style={{ minHeight: "100vh", background: "#013e06", minWidth: "100vw" }}>
    <Header/>
      <Container className="d-flex align-items-center justify-content-center" style={{minHeight:'calc(100vh - 150px)'}}>
      
      <div className="w-100" style={{ maxWidth: "400px"}}>
        <Card>
          <Card.Body>
            <h2 className="text-center mb-4">Sign Up</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email">
                <Form.Label>Enter Your Email Address</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              {emailSent && <p className="mt-0 mb-0">Check your email inbox to log in. Check spam folder if email not found.</p>}
              <button disabled={loading || emailSent} className="w-100 mt-4 roundedButtonStyle" type="submit">
                {loading ? 'Creating sign in link...' : 'Sign Up'}
              </button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Container>
    </div>
  )
}