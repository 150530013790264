import './App.css';
import Leads from './page/Leads';
import { MyLeads } from './page/MyLeads';
import Pricing from './page/Pricing';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './page/Login';
import { AuthProvider } from './contexts/AuthContext';
import Signup from './page/Signup';
import Content from './page/Content';
import ForgotPassword from './page/ForgotPassword';
import AuthLink from './page/AuthLink';
import { MyLeadsList } from './page/MyLeadsList';
import { getAnalytics } from "firebase/analytics"
import app from './firebase';
import { useEffect } from 'react';

function App(): JSX.Element {
  useEffect(() => {
    getAnalytics(app)
  }, [])
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
            <Route path="/leads" Component={Leads}/>
            <Route path="/my-leads" Component={MyLeads}/>
            <Route path="/pricing" Component={Pricing}/>
            <Route path="/log-in" Component={Login}/>
            <Route path="/auth-link" Component={AuthLink}/>
            <Route path="sign-up" Component={Signup}/>
            <Route path="forgot-password" Component={ForgotPassword}/>
            <Route path="/list/*" Component={MyLeadsList}/>
            <Route path="*" Component={Content}/>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}


export default App;
