import React from "react"
import { Lead, LeadSifter } from "../repo/Order";

interface LeadProps {
    displayedLeads: Lead[] | null,
    leadSifter: LeadSifter
}

export const LeadView: React.FC<LeadProps> = ({ displayedLeads, leadSifter }) => {
    return (
        <div style={containerStyle}>
          <div style={gridHeaderStyle}>
            {leadSifter.visibleFields?.map((field) => (
              <div style={headerItemStyle} key={field}>
                <div style={{color: 'white'}}>
                  {field}
                </div>
              </div>
            ))}
          </div>
          <div style={gridBodyStyle}>
            {displayedLeads?.map((lead, rowIndex) => (
              <div key={rowIndex} style={rowStyle}>
                {lead.values.map((value, colIndex) => (
                  <div key={`${rowIndex}${colIndex}`} style={cellStyle}>
                    {`${value}`.includes("http:") || `${value}`.includes("https:") ? 
                      <a href={`${value}`} target="_blank" className="hyperlink" rel="noreferrer">{`${value}`}</a> :
                      `${leadSifter.visibleFields[colIndex]}`.includes("phone") ?
                      <a href={`tel:${value}`} className="hyperlink">{`${value}`}</a> :
                      `${leadSifter.visibleFields[colIndex]}`.includes("email") ?
                      <a href={`mailto:${value}`} className="hyperlink">{`${value}`}</a> :
                      value == null ?
                      'Nothing' :
                      typeof value === 'object' ? 
                      Object.entries(value).map(([key, val]) => `${key}: ${val}`).join(', ') : 
                      `${value}`
                    }
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div>
            
          </div>
        </div>
      );
    };

// CSS styles
const containerStyle: React.CSSProperties = {
    maxHeight: '60vh',
    overflowX: 'auto',
    overflowY: 'auto',
    border: "1px solid black",
};

const gridHeaderStyle: React.CSSProperties = {
    display: 'flex',
};

const headerItemStyle: React.CSSProperties = {
    flex: 1,
    padding: '5px',
    minWidth: '160px',
    background: '#013e06',
    wordWrap: 'break-word', // Allow long words to be wrapped
    overflowWrap: 'break-word', // Ensure words are not cut off at the container's edge
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
};

const gridBodyStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
};

const rowStyle: React.CSSProperties = {
    display: 'flex',
};

const cellStyle: React.CSSProperties = {
    flex: 1,
    padding: '5px',
    minWidth: '160px',
    background: '#eaffe3',
    wordWrap: 'break-word', // Allow long words to be wrapped
    overflowWrap: 'break-word', // Ensure words are not cut off at the container's edge
    borderTop: '1px solid #ccc',
    borderRight: '1px solid #ccc',
  };
