import React from 'react'
import { Filter } from '../repo/Order'

interface FilterProps {
    filter: Filter,
    toggle: () => void
}

export const FilterView: React.FC<FilterProps> = ({ filter, toggle }) => {
    const isChecked = filter.active;

    const handleCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
    };
  
    return (
      <div onClick={toggle} key={`${filter.name}`} style={filterItemStyle}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxClick}
          style={checkboxStyle}
        />
        <h3 className='blackText'>{filter.name}</h3>
      </div>
    );
}

const filterItemStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center', // Vertically center the checkbox and text
    cursor: 'pointer',
  };
  
  const checkboxStyle: React.CSSProperties = {
    marginRight: '8px', // Add some spacing between the checkbox and text
    marginTop: '12px'
  };

