import React, { useEffect } from 'react'
import { getAuth } from 'firebase/auth'
import Header from '../Header';

const Pricing: React.FC = () => {
    useEffect(() => {
      goToCheckout(0)
    }, []);

    return (
      <div className='App'>
        <Header/>
      </div>
    );
  };

  const goToCheckout = (id: number) => {
    const user = getAuth().currentUser
    if (user == null) return window.location.href = '../sign-up'
    getAuth().currentUser?.getIdToken().then((token) => {
      fetch(process.env.REACT_APP_SERVER_URL + `/create-checkout-session/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`
        },
        body: JSON.stringify({ productId: id }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Network response was not ok.");
          }
        })
        .then((data) => {
          window.location.href = data.checkoutUrl;
        })
        .catch((error) => {
          console.error("Error:", error);
        });
  })
  
};
  
export default Pricing