import demo from '../Demo.png';
// import star from '../stars.png';
// import frog from '../froggy.png';
// import clock from '../clocky.png';
// import pig from '../piggy.png';
import Header from '../Header';
import Footer from '../Footer';


const Content = () => {
  return (
    <div style ={{ background: "#d4e3d5" }}>

    <Header/>
    <div className="position-relative">
      <img
        src={demo}
        className="img-fluid"
        style={{ objectFit: 'cover', width: '100%', height: "200vh", opacity: '0.03' }}
        alt=""
      />
      <div className="position-absolute top-0 start-0 w-100">
        <h1 className="header text-center mt-5 p-2 fs-1">Close your next deal with the best cold calling tool to target small businesses.</h1>
        <div className='d-flex justify-content-center'>
        <h2 className="subheader text-center fs-4">📧📞 Quickly contact qualified businesses from google maps to offer your services.</h2>
        </div>
        
        {/* <h2 className="subheader">60 Second Demo of Laplead's Power</h2> */}

        <div className='d-flex justify-content-center'>
            <iframe width="800" height="450" src="https://www.youtube.com/embed/tKNs_dEPLRM?si=EQbyYA7JXNiiM26I" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>

          <h2 className="subheader text-center fs-4">Step 1. Watch video to learn how to use and see if this is right for you. </h2>
          <h2 className="subheader text-center fs-4">Step 2. Sign up, save hours of time, and close more deals!</h2>
        <div className='d-flex justify-content-center'>
          <button onClick={() => { window.location.href = "/sign-up" }} className='roundedButtonStyle'>Sign up now & get leads fast!</button>
        </div>

        {/* <div className='pt-1 mt-5 p-2' style={{background: '#013e06'}}>

          <br/><br/>
          <div className='d-flex justify-content-center'>
            <img
              src={star}
              style={{height:'100px'}}
              alt="4.6 stars"
            />
          </div>
          <div className='justify-content-center p-5' style={{maxWidth: '1000px', margin: '0 auto'}}>
            <h2 className="subheader text-center" style={{color: 'white'}}><strong>Over 150 businesses got new clients through Laplead leads.</strong></h2>
            <br/>
            <h3 style={{color: 'white'}}>"Just one client from these leads pays it back 100x."
            </h3>
            <br/>
            <h3 style={{color: 'white'}}>“I was manually searching location by location to find leads on google maps, but now it gets done automatically.”</h3>
            <br/>
            <h3 className='mb-5' style={{color: 'white'}}>“I got a new account in one week with the leads just from the sign up bonus.”</h3>
  
            <div className='d-flex justify-content-center'>
              <button onClick={() => { window.location.href = "/sign-up" }} className='roundedButtonStyle'>Sign up and get 200 free leads</button>
            </div>
            <div className='d-flex justify-content-center mb-5' style={{color: 'white'}}>
              <p>*no credit card required*</p>
            </div>
          </div>

          <div className='d-flex justify-content-center'>

          </div>

          </div> */}

          <div className='pt-1' style={{background: '#d4e3d5'}}>

          <br/><br/>
          <div className='d-flex justify-content-center'>
          </div>

          <div className='p-5' style={{maxWidth: '1000px', margin: '0 auto'}}>
            <h1 className="mt-4 mb-4">Find Every Small Bussiness from a Business Category</h1>
            <h2>🔍 Enter Business Category.</h2>
            <p>Laplead is built for finding niche business types because we understand your company is best suited towards helping specific types of businesses.
              Any business can be entered, but the suggested categories will return more specific results. Finding the closest term to what you want and selecting the suggested category is recommended for most cases.
            </p>

            <br/>

            <h2>📍 Select Searchable Locations.</h2>
            <p>Type a state and select a state to place location pins all over the entire state for a state-wide search
              or select the map and click to get businesses around the area where you clicked. Locations are remembered so you can avoid gathering from the same spot.
            </p>

            <br/>
            <h2>📋 Collect Business Category from Locations.</h2>
            <p>Once you have entered the business you are looking for and selected at least 1 location on the map you can collect the businesses from the selected locations.
               Laplead searches these locations real-time and compiles the latest data on websites. Once you click collect you can come back later to be greeted with a treasure trove of leads within 30 minutes or wait and the data updates as we get it.
            </p>
          </div>

          {/* <h1 style={{fontWeight: '600', fontSize: "1em"}} className="subheader text-center p-3">Watch this video to see how you can get 100+ free leads right now with 60 seconds of work</h1> */}

          <div className='d-flex justify-content-center mt-4'>
          <button onClick={() => { window.location.href = "/sign-up" }} className='roundedButtonStyle'>Sign up & preview the software!</button>
          </div>
          <div className='p-5' style={{maxWidth: '1000px', margin: '0 auto'}}>
            <h1 className="mt-4 mb-4">FAQs</h1>
            <h2>Where does this data come from?</h2>
            <p>Laplead gets the base data from searching search terms on Google. We then use data found on google, such as the company's website, to find all publically available information about each company.
            </p>

            <br/>
            <h2>What can I do with this data?</h2>
            <p>You can run email, phone, & social media outreach campaigns and download the data. The data we get acts as top-of-funnel leads which is useful for growing your customer base. Laplead aims to make it easy for you to find businesses to pitch your services to. </p>

            <br/>
            <h2>How many searches can I submit?</h2>
            <p>As many as you want! The more credits you have the faster you can get the data because for each search we take 50 credits as escrow until the location search is complete. We continue to loop through all your searches until either all your locations are complete or you run out of laplead credits. </p>
         
            <br/>
            <h2>What's the max number of businesses I can get per search?</h2>
            <p>You can get up to 50 businesses per location pin on the map. We do this to ensure a reasonable radius based on the location pin.</p>
           
            <br/>
            <h2>What happens if I run out of credits mid-order?</h2>
            <p>The locations that fail automatically get added to your locations in “Let's Collect Leads” to make it effortless to continue where you left off.</p>
           
            <br/>
            <h2>How much faster and cheaper is this than a VA?</h2>
            <p>Much faster, much cheaper, and much more effective than a virtual assistant. All data comes in within 30 minutes and Laplead also remembers where you previously searched to make it easier to discover new small businesses. And your current or future VA can manage your Laplead with little training.</p>
           
            <br/>
            <h2>What if I want to get more companies?</h2>
            <p>You can buy more credits from Laplead. Because it is in your best interest, we don't do a subscription at this time.</p>

            <br/>
            <h2>What happens if you don't use all your credit?</h2>
            <p>You can use them whenever. Credits never expire.</p>
           </div>
        <div className='d-flex justify-content-center'>
          <button onClick={() => { window.location.href = "/sign-up" }} className='roundedButtonStyle'>Click here to log in to Laplead</button>
        </div>
          <br/>
          <br/>
          <br/>
          
          <Footer/>
          </div>
      </div>
    </div>
    </div>
    )
};

export default Content;
