import React, { DragEvent } from 'react';
import { TextField } from '../repo/Order';
import dehaze from '../dehaze.png'; // Import the logo image

interface ColumnProps {
    columnItems: TextField[];
    setColumnItems: (text: TextField[]) => void;
    remove: (field: string) => void
}

export const Column: React.FC<ColumnProps> = ({ columnItems, setColumnItems, remove }) => {

  const handleDragStart = (event: DragEvent<HTMLDivElement>, index: number) => {
    event.dataTransfer.setData('text/plain', `${index.toString()} ${columnItems[0].isVisible}`);
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>, targetIndex: number) => {
    event.preventDefault();
    const data = event.dataTransfer.getData('text/plain').toString().split(" ");
    const sourceIndex = parseInt(data[0])
    const isVisible = data[1]
    if (isVisible !== `${columnItems[0].isVisible}`) return
    const updatedItems = [...columnItems];
    const [movedItem] = updatedItems.splice(sourceIndex, 1);
    updatedItems.splice(targetIndex, 0, movedItem);
    setColumnItems(updatedItems);
  };

  return (
    <div >
      {columnItems.map((item, index) => (
        <div style={flexStyle} key={`${item.text}`}>
        <div
          style={item.isVisible ? appliedSortItemStyle : applySortItemStyle}
          key={item.text}
          draggable
          onDragStart={(event) => handleDragStart(event, index)}
          onDragOver={handleDragOver}
          onDrop={(event) => handleDrop(event, index)}
        >
          <img alt="Dehaze icon" style={{width: '20px', height: '20px', marginTop: "14px"}} src={dehaze}/>
          <h3 style={textStyle}>{item.text}</h3>
        </div>
          <h3 style={hideStyle} onClick={() => remove(item.text)}>{item.isVisible ? "hide" : "show"}</h3>
        </div>
      ))}
    </div>
  );
};

  
const textStyle: React.CSSProperties = {
  wordWrap: 'break-word', // Allow long words to be wrapped
  overflowWrap: 'break-word', // Ensure words are not cut off at the container's edge
  margin: '10px',
  color: 'black',
}

const hideStyle: React.CSSProperties = {
    wordWrap: 'break-word', // Allow long words to be wrapped
    overflowWrap: 'break-word', // Ensure words are not cut off at the container's edge
    margin: '10px',
    cursor: 'pointer',
    color: 'black',
    textDecoration: 'underline',
  }
  
  const applySortItemStyle: React.CSSProperties = {
    border: "1px solid black",
    marginTop: '1px',
    paddingLeft: '4px',
    background: '#eaffe3',
    cursor: 'pointer',
    display: 'flex',
  };
  
const appliedSortItemStyle: React.CSSProperties = {
  border: "1px solid black",
  marginTop: '1px',
  paddingLeft: '4px',
  background: '#b0ff8f',
  cursor: 'pointer',
  display: 'flex',
  };

const flexStyle: React.CSSProperties = {
    display: "flex"
  };
  
